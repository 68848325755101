import { BaseURL } from "./ApiRequestUtil";

export const B2CConstants = {
    grantType: 'client_credentials',
    scope: `${BaseURL.token}/x/.default`,
    policy: `B2C_1A_ClientCredentials`,
}

export enum ValidDomain {
    Emistesting = '.emistesting.co.uk',
    Emishosting = '.emishosting.com',
    Emishealth = '.emishealth.com',
    EmishealthSolutions = '.emishealthsolutions.com',
    EmisxUk = '.emis-x.uk',
}