import { injectable } from "inversify";
import { BaseURL, getAccessToken } from "../../utils/ApiRequestUtil";
import { AppConfig } from "../../utils/AppConfig";
import { DD_APIEndPointName } from "../../utils/DDAPIEndpointName";
import { APIClientElectron } from "../APIClientElectron";
import { MessagesParameters } from "../apiParameterModels/MessagesParameters";
import { IMessagesRepository } from "../interfaces/IMessagesRepository";

@injectable()
export class MessagesRepository implements IMessagesRepository {
    getMessages(params: MessagesParameters): Promise<string> {
        return new Promise((resolve, reject) => {
            const accessToken = `Bearer ${getAccessToken()}`
            APIClientElectron.getRequestWithOutCertificate(
                DD_APIEndPointName.MessageList,
                `${BaseURL.emisXBaseURL}${AppConfig.emisxAPIUrlEndPoint.Message}`,
                accessToken,
                params)
                .then(response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }
}