import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import { Button, IconButton, List, ListItem, styled, Typography } from "@mui/material";
import { ColorConstant } from "../../../constants/ColorConstant";
import { SizeConstant } from "../../../constants/SizeConstant";

/* BookAppointmentContainer */
export const BookAppointmentContainerView = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ColorConstant.WHITE,
    height: '100vh',
    width: '100vw',
    userSelect: 'none'
});

export const BookAppointmentContainerTopView = styled('div')({
    backgroundColor: ColorConstant.WHITE,
});

export const BookAppointmentContainerScrollableView = styled('div')({
    overflowY: 'auto',
});

/* BookAppointmentTypeSelection */
export const BookAppointmentTypeSelectionContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: 'calc(100vh - 20px)',
    padding: '10px 10%',
    overflow: 'hidden',
    userSelect: 'none',
});

export const BookAppointmentTypeSelectionDividerView = styled('div')({
    height: '2px',
    backgroundColor: ColorConstant.WHITE_EDGAR,
});

export const LargeGridButton = styled(Button)({
    width: `${SizeConstant.BookAppointment.AppointmentTypeGrid.width}px`,
    height: `${SizeConstant.BookAppointment.AppointmentTypeGrid.height}px`,
    padding: '20px',
    margin: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'x-large',
    fontWeight: '500',
    color: 'white',
    backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    borderRadius: 5,
    '&:hover': {
        backgroundColor: ColorConstant.EMERALD_STONE,
    },
    textTransform: 'none',
});

export const ChooseSpecificDateButton = styled(Button)({
    fontSize: 'x-large',
    fontWeight: 'bold',
    justifyContent: 'left',
    backgroundColor: ColorConstant.EMERALD_STONE,
    textTransform: 'none',
    '&:hover': {
        backgroundColor: ColorConstant.EMERALD_STONE,
    },
    padding: 20,
});

export const StyledCalendarMonthIcon = styled(CalendarMonthIcon)({
    color: ColorConstant.WHITE, 
    padding: '10px 0px',
});

export const AppointmentByWeekMonthButton = styled(Button)({
    textTransform: 'none',
    fontSize: 'x-large',
    fontWeight: 'bold',
    justifyContent: 'left',
    backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    '&:hover': {
        backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    },
    padding: 10,
    flexDirection: 'column',
    alignItems: 'flex-start'
});

export const AppointmentByWeekMonthButtonTitle = styled('span')({
    fontSize: 'x-large',
    fontWeight: 'bold',
    color: ColorConstant.WHITE,
    marginBottom: '-10px',
});

export const AppointmentByWeekMonthButtonSubtitle = styled('span')({
    fontSize: 'large',
    color: ColorConstant.WHITE,
});

export const BookThisButton = styled(Button)({
    textTransform: 'none',
    fontSize: 'larger',
    fontWeight: 'bold',
    justifyContent: 'left',
    backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    '&:hover': {
        backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    },
    padding: 10,
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: ColorConstant.WHITE,
});

export const BookAppointmentTypeSelectionHeaderQuestionView = styled('div')({
    display: 'flex',
    marginTop: '20px',
    justifyContent: 'center'
});

export const BookAppointmentTypeSelectionHeader = styled('div')({
    height: '100px',
});

export const BookAppointmentTypeSelectionTopContainer = styled('div')({
    display: 'flex',
    height: '80px',
    gap: '5px',
    flex: 1,
});

export const BookAppointmentTypeSelectionUserNameContainer = styled('div')({
    display: 'flex',
    flex: 1,
    gap: '5px',
    alignSelf: 'center',
});

export const BookAppointmentTypeSelectionHiText = styled('span')({
    fontSize: 'xx-large',
    fontWeight: 'normal',
    color: ColorConstant.IRON,
});

export const BookAppointmentTypeSelectionUserNameText = styled('span')({
    fontSize: 'xx-large',
    fontWeight: 'bold',
    color: ColorConstant.IRON,
});

export const BookAppointmentTypeSelectionNotYouButton = styled(Button)({
    backgroundColor: 'white',
    cursor: 'pointer',
    border: `5px solid ${ColorConstant.CERAMIC_BLUE_TURQUOISE}`,
    borderRadius: '5px',
    minWidth: '200px',
    fontSize: 'x-large',
    fontWeight: 'bold',
    color: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    alignSelf: 'center',
    padding: '5px 10px',
    '&:hover': {
        backgroundColor: ColorConstant.EMERALD_STONE,
        color: 'white',
    },
    textTransform: 'none',
});

/* BookAppointmentSelection */
export const BookAppointmentSelectionContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 20px)',
    width: '100%',
    backgroundColor: 'white',
    overflow: 'hidden',
    userSelect: 'none',
});

export const BookAppointmentSelectionInnerContainer = styled('div')({
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
});

export const AppointmentTypesContainer = styled('div')({
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
});

export const HeaderQuestionContainer = styled('div')({
    display: 'flex',
    marginTop: 50,
    marginBottom: 20,
    justifyContent: 'center'
});

export const RightSideContainer = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowY: 'auto',
    backgroundColor: ColorConstant.BELUGA,
});

export const SlotTitleText = styled('div')({
    display: 'flex',
    color: ColorConstant.BLACK_SPACE,
    backgroundColor: ColorConstant.BLIZZARD_BLUE,
    fontSize: 'xx-large',
    justifyContent: 'center',
    padding: '20px',
});

export const NextAvailableAppointmentContainer = styled('div')({
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
});

export const RightSideScrollableContainer = styled('div')({
    overflowY: 'auto',
});

export const SlotDateText = styled('div')({
    fontWeight: 'bold',
    fontSize: 'large',
    color: ColorConstant.EMERALD_STONE,
    textAlign: 'left',
    padding: '5px 20px',
    marginTop: '20px',
});

export const SlotTimeText = styled('div')({
    fontWeight: 'bold',
    fontSize: 'large',
    color: ColorConstant.EMERALD_STONE,
    textAlign: 'left',
    padding: '5px 20px',
});

export const SlotTypeText = styled('div')({
    fontSize: 'large',
    fontWeight: 'bold',
    textAlign: 'left',
    padding: '5px 20px',
    color: ColorConstant.CERAMIC_BLUE_TURQUOISE,
});

export const SessionHolderNameText = styled('div')({
    color: ColorConstant.BLACK_SPACE,
    fontSize: 'large',
    fontWeight: 600,
    textAlign: 'left',
    padding: '5px 20px',
});

export const SlotDivider = styled('div')({
    display: 'flex',
    margin: '20px',
    backgroundColor: ColorConstant.BLACK_SPACE,
    height: '2px',
});

export const SlotLocationText = styled('div')({
    fontSize: 'large',
    fontWeight: 'bold',
    textAlign: 'left',
    padding: '5px 20px',
    color: ColorConstant.BLACK_SPACE,
});

export const AppointmentTypesView = styled('div')({
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
});

export const AppointmentTypesInnerView = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: '0% 20%',
    gap: '30px',
    overflowY: 'auto',
});

/*BookAppointmentConfirmation*/
export const BookAppointmentConfirmationContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 20px)',
    width: '100%',
    overflow: 'hidden',
    userSelect: 'none',
});

export const BookAppointmentConfirmationInnerContainer = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowY: 'auto'
});

export const TitleText = styled('div')({
    textAlign: 'left',
    color: ColorConstant.BLACK_SPACE,
    fontSize: 'xx-large',
    fontWeight: 'bold',
    margin: '2% 7%',
});

export const SlotContentView = styled('div')({
    display: 'flex', flex: 1,
    flexDirection: 'column',
    overflowY: 'auto',
    margin: '2% 7%'
});

export const SlotView = styled('div')({
    flexDirection: 'row',
    display: 'flex',
    backgroundColor: ColorConstant.BELUGA,
    width: '100%',
    height: '130px',
    justifyContent: 'space-between',
});

export const SlotInfoView = styled('div')({
    display: 'flex',
    flex: 0.5,
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
});

export const SlotDateTextInConfirmation = styled('div')({
    fontWeight: 'bold',
    fontSize: 'large',
    color: ColorConstant.EMERALD_STONE,
    textAlign: 'left',
    padding: '5px 0px 10px 20px',
});

export const SlotTimeTextInConfirmation = styled('div')({
    fontWeight: 'bold',
    fontSize: 'large',
    color: ColorConstant.EMERALD_STONE,
    textAlign: 'left',
    padding: '5px 0px 10px 20px',
});

export const SlotTimeTypeView = styled('div')({
    display: 'flex',
    flexDirection: 'row',
});

export const SlotTypeTextInConfirmation = styled('div')({
    fontSize: 'large',
    fontWeight: 'bold',
    textAlign: 'left',
    padding: '5px 20px',
    color: ColorConstant.CERAMIC_BLUE_TURQUOISE,
});

export const SessionHolderNameTextInConfirmation = styled('div')({
    color: ColorConstant.BLACK_SPACE,
    fontSize: 'large',
    fontWeight: 600,
    textAlign: 'left',
    padding: '5px 20px',
});

export const SlotHolderNameText = styled('div')({
    color: ColorConstant.BLACK_SPACE,
    fontSize: 'large',
    fontWeight: 600,
    textAlign: 'left',
    padding: '5px 20px',
});

export const SlotLocationTextInConfirmationContainer = styled('div')({
    flex: 0.5
});

export const SlotLocationTextInConfirmation = styled('div')({
    fontSize: 'large',
    fontWeight: 'bold',
    textAlign: 'left',
    padding: '20px',
    color: ColorConstant.BLACK_SPACE,
});

export const DividerInConfirmation = styled('div')({
    display: 'flex',
    backgroundColor: ColorConstant.Divider,
    height: '90%',
    alignSelf: 'center',
    width: '3px',
    border: 'none',
    borderRadius: '5px',
});

export const ReasonContainer = styled('div')({
    margin: '20px 0px',
    padding: '0px 0px',
});

export const ReasonHeaderText = styled('div')({
    textAlign: 'left',
    padding: '10px 0px',
    fontSize: 'medium',
    color: ColorConstant.IRON,
    fontWeight: 'bold',
});

export const ReasonFooterText = styled('div')({
    textAlign: 'right',
    margin: '-20px 10px',
    fontSize: 'small',
    color: ColorConstant.IRON,
    fontWeight: 'bold'
});

export const FooterButtonView = styled('div')({
    height: '80px',
    flexDirection: 'column',
    margin: '2% 7%',
});

export const BookAppointmentButton = styled('button')({
    width: '100%',
    height: '100%',
    backgroundColor: ColorConstant.EMERALD_STONE,
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    fontSize: 'x-large',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    },
});

/* BookAppointmentSlotSelection */
export const BookAppointmentSlotSelectionContainer = styled('div')({
    display: 'flex',
    flex: 1,
    height: '100vh',
    flexDirection: 'column',
    backgroundColor: 'white',
    overflowY: 'hidden',
});

export const BookAppointmentTopContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    paddingTop: '20px',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100vw',
    height: SizeConstant.BookAppointment.SlotSelection.dateStripHeight,
});

export const CalendarIconButton = styled(IconButton)<{ isShowIcon: boolean, isSmallDimension: boolean }>(({ isShowIcon, isSmallDimension }) => ({
    display: 'flex',
    backgroundColor: isShowIcon ? ColorConstant.CERAMIC_BLUE_TURQUOISE : ColorConstant.WHITE,
    alignItems: 'center',
    justifyContent: 'center',
    aspectRatio: '1 / 1',
    borderRadius: 5,
    margin: 10,
    marginLeft: isSmallDimension ? 10 : 20,
    '&:hover': {
        backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE
    }
}));

export const StyledCalendarIcon = styled(CalendarMonthTwoToneIcon)({
    color: 'white',
    fontSize: 50,
});

export const BookAppointmentTopDivider = styled('div')({
    backgroundColor: ColorConstant.EMERALD_STONE,
    height: SizeConstant.BookAppointment.SlotSelection.divider,
    width: '100vw',
});

export const BookAppointmentMainContainer = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    backgroundColor: ColorConstant.BELUGA,
    height: isMobile ? 'calc(100vh - 155px)' : 'calc(100vh - 500px)',
}));

export const NoAppointmentText = styled('div')({
    display: 'flex', 
    flex: 1, 
    color: ColorConstant.IRON, 
    alignItems: 'center', 
    justifyContent: 'center', 
    fontSize: 'larger'
})

export const SlotHolderNameList = styled(List)({
    height: '100%',
    width: '250px',
    overflowY: 'auto'
});

export const SlotHolderNameListItem = styled(ListItem)<{ isSelected: boolean }>(({ isSelected }) => ({
    backgroundColor: isSelected ? ColorConstant.EMERALD_STONE : 'white',
    color: isSelected ? 'white' : ColorConstant.EMERALD_STONE,
}));

export const BookAppointmentSlotsMainView = styled('div')({
    display: 'flex', 
    flex: 1,
    flexDirection: 'column', 
    margin: '10px 50px', 
    width: '100%', 
    overflowY: 'hidden',
})

export const BookAppointmentSlotSelectionSelectedDateText = styled(Typography)({
    fontSize: "larger",
    fontWeight: 'bold',
    color: ColorConstant.BLACK_SPACE,
    textAlign: 'left',
});

export const BookAppointmentSlotListView = styled('div')({
    flexDirection: 'column',
    overflowY: 'auto',
})

export const BookAppointmentSlotSelectionGridContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    border: `0.5px solid ${ColorConstant.CERAMIC_BLUE_TURQUOISE}`,
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 10,
    overflowY: 'hidden',
});

export const BookAppointmentSlotSelectionGrid = styled('div')({
    overflowY: 'auto',
    flexDirection: 'column',
    display: 'flex',
    padding: 20,
});

export const BookAppointmentSlotSelectionSessionText = styled(Typography)({
    backgroundColor: ColorConstant.BELUGA,
    textAlign: 'left',
    color: ColorConstant.BLACK_SPACE,
    overflowY: 'hidden',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: 10,
});

export const SlotTimeGridButton = styled(Button)(({ theme }) => ({
    width: `${SizeConstant.BookAppointment.SlotSelection.SlotTimeButton.width}px`,
    height: `${SizeConstant.BookAppointment.SlotSelection.SlotTimeButton.height}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'larger',
    fontWeight: '500',
    color: 'white',
    backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    borderRadius: 5,
    '&:hover': {
        backgroundColor: ColorConstant.EMERALD_STONE,
    },
}));

/* CalendarDateItem */
export const CalendarDateItemContainer = styled('div')<{ isSmallDimension: boolean }>(({ isSmallDimension }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: isSmallDimension ? 5 : 10,
    userSelect: 'none',
}));

export const DayGridItemButton = styled(Button)<{ isSelectedDate: boolean, isSmallDimension: boolean }>(({ isSelectedDate, isSmallDimension }) => ({
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    borderRadius: 5,
    cursor: 'pointer',
    padding: isSmallDimension ? 5 : 10,
    height: '80%',
    alignSelf: 'center',
    backgroundColor: isSelectedDate ? ColorConstant.EMERALD_STONE : ColorConstant.BELUGA,
    '&:hover': {
        backgroundColor: isSelectedDate ? ColorConstant.CERAMIC_BLUE_TURQUOISE : ColorConstant.GREY,
    },
}));

export const DateStripDayText = styled(Typography)<{ textColor: string, isSmallDimension: boolean }>(({ textColor, isSmallDimension }) => ({
    fontSize: isSmallDimension ? 'medium' : 'large',
    fontWeight: 'normal',
    alignSelf: 'center',
    color: textColor,
    textTransform: 'none',
}));

export const DateStripDateMonthText = styled(Typography)<{ textColor: string, isSmallDimension: boolean }>(({ textColor, isSmallDimension }) => ({
    fontSize: isSmallDimension ? 'medium' : 'large',
    fontWeight: 'bold',
    alignSelf: 'center',
    color: textColor,
    textTransform: 'none',
}));

/*BookAppointmentSuccess*/
export const BookAppointmentSuccessContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 20px)',
    width: '100%',
    overflow: 'hidden',
});

export const BookAppointmentSuccessInnerContainer = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
});

export const BookAppointmentTopView = styled('div')({
    flex: 1,
});

export const TitleView = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    margin: '2% 7%',
    marginBottom: '0%',
});

export const TitleTextInSuccess = styled('div')({
    textAlign: 'left',
    color: ColorConstant.BLACK_SPACE,
    fontSize: 'xx-large',
    fontWeight: 'bold',
    marginLeft: '10px',
    alignSelf: 'center'
});

export const SlotContentViewInSuccess = styled('div')({
    display: 'flex',
    margin: '2% 7%',
});

export const SlotViewInSuccess = styled('div')({
    flexDirection: 'row',
    display: 'flex',
    backgroundColor: ColorConstant.BELUGA,
    width: '100%',
    height: '130px',
    justifyContent: 'space-between',
});

export const SlotInfoViewInSuccess = styled('div')({
    flex: 0.5
});

export const SlotDateTextInSuccess = styled('div')({
    fontWeight: 'bold',
    fontSize: 'large',
    color: ColorConstant.EMERALD_STONE,
    textAlign: 'left',
    padding: '5px 0px 10px 20px',
    marginTop: '20px',
});

export const SlotTimeTextInSuccess = styled('div')({
    fontWeight: 'bold',
    fontSize: 'large',
    color: ColorConstant.EMERALD_STONE,
    textAlign: 'left',
    padding: '5px 0px 10px 20px',
    paddingRight: '5px',
});

export const SlotTimeTypeViewInSuccess = styled('div')({
    display: 'flex',
    flexDirection: 'row',
});

export const SlotTypeTextInSuccess = styled('div')({
    fontSize: 'large',
    fontWeight: 'bold',
    textAlign: 'left',
    padding: '5px 20px',
    color: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    paddingLeft: '5px',
});

export const SlotHolderNameTextInSuccess = styled('div')({
    color: ColorConstant.BLACK_SPACE,
    fontSize: 'large',
    fontWeight: 600,
    textAlign: 'left',
    padding: '5px 20px',
});

export const DividerInSuccess = styled('div')({
    display: 'flex',
    backgroundColor: ColorConstant.Divider,
    height: '90%',
    alignSelf: 'center',
    width: '2px',
    border: 'none',
    borderRadius: '5px',
});

export const SlotLocationTextInSuccessContainer = styled('div')({
    flex: 0.5
});

export const SlotLocationTextInSuccess = styled('div')({
    fontSize: 'large',
    fontWeight: 'bold',
    textAlign: 'left',
    padding: '20px',
    color: ColorConstant.BLACK_SPACE,
});

export const FooterButtonViewInSuccess = styled('div')({
    height: '80px',
    flexDirection: 'column',
    margin: '2% 7%',
});

export const DoneButtonInSuccess = styled('button')({
    width: '100%',
    height: '100%',
    backgroundColor: ColorConstant.EMERALD_STONE,
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    fontSize: 'x-large',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    },
});

/* BookAppointmentCalendarScreen */
export const BookAppointmentCalendarScreenContainer = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
});

export const BookAppointmentCalendarScreenTitleText = styled('div')({
    color: ColorConstant.DUGONG,
    backgroundColor: 'white',
    fontSize: 'x-large',
    fontWeight: 'bold',
    padding: '20px',
});

export const BookAppointmentCalendarScreenInnerContainer = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
});

export const BookAppointmentCalendarScreenPreviousContainer = styled(Button)({
    display: 'flex',
    width: '10%',
    minWidth: '100px',
    height: '100%',
    border: 'none',
    justifyContent: 'center',
});

export const BookAppointmentCalendarScreenPreviousText = styled('div')({
    color: 'white',
    fontSize: 'xxx-large',
    fontWeight: 'bold',
    padding: '20px',
    alignSelf: 'center',
});

export const BookAppointmentCalendarScreenNextContainer = styled(Button)({
    display: 'flex',
    width: '10%',
    minWidth: '100px',
    height: '100%',
    border: 'none',
    justifyContent: 'center',
    backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    '&:hover': {
        backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    },
});

export const BookAppointmentCalendarScreenNextText = styled('div')({
    color: ColorConstant.WHITE,
    fontSize: 'xxx-large',
    fontWeight: 'bold',
    padding: '20px',
    alignSelf: 'center',
});

export const BookAppointmentCalendarScreenCalendarContainer = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
});

export const BookAppointmentCalendarScreenMonthYearText = styled('div')({
    color: ColorConstant.BLUE_TUNA,
    backgroundColor: ColorConstant.BELUGA,
    fontSize: 'x-large',
    fontWeight: 'bold',
    padding: '20px',
});

export const BookAppointmentCalendarScreenDayGridContainer = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: ColorConstant.BELUGA,
    margin: 0,
});

export const BookAppointmentCalendarDayNameContainer = styled('div')({
    backgroundColor: ColorConstant.DR_WHITE, 
    display: 'flex',
    width: '100%'
});

export const BookAppointmentCalendarDayNameText = styled(Typography)({
    height: '5vh',
    width: '10vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    fontSize: 'large',
});

export const BookAppointmentCalendarDayGridContainer = styled('div')({
    display: 'flex', 
    width: '100%'
});

export const BookAppointmentCalendarDayButton = styled(Button)({
    height: '8vh',
    width: '10vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px',
    fontSize: 'x-large',
    fontWeight: 'bold',
    color: 'white',
    borderRadius: 5,
});

export const BookAppointmentCalendarScreenFooterContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: ColorConstant.BELUGA,
    height: '40px',
    justifyContent: 'center',
    gap: '10px',
});

export const BookAppointmentCalendarScreenFooterInnerContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
});

export const BookAppointmentCalendarScreenFooterInnerContainerText = styled('div')({
    padding: '10px',
    color: ColorConstant.DUGONG,
    fontSize: 'medium',
});

export const BookAppointmentCalendarScreenFooterInnerContainerAppointmentLegend = styled('div')({
    height: '80%',
    aspectRatio: '1',
    backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    border: 'none',
    borderRadius: '5px',
});

export const BookAppointmentCalendarScreenFooterInnerContainerNoAppointmentLegend = styled('div')({
    height: '80%',
    aspectRatio: '1',
    backgroundColor: ColorConstant.DESIRED_DAWN,
    border: 'none',
    borderRadius: '5px',
});

export const BookAppointmentCalendarScreenFooterInnerContainerTodayLegend = styled('div')({
    height: '50%',
    aspectRatio: '1',
    border: `5px solid ${ColorConstant.MICA_CREEK}`,
    borderRadius: '5px',
});

export const QuestionnaireKeyboardContainerView = styled('div')({
    display: 'flex',
    backgroundColor: 'black',
    paddingTop: 10,
    width: '100%',
    justifyContent: 'center',
})

export const QuestionnaireTextView = styled('div')(({ theme }) => ({
    justifyContent: 'flex-start',
    height: '10vh',
    border: `2px solid ${ColorConstant.CLOUDED_VISION}`,
    textAlign: 'left',
    padding: 20,
    minHeight: '5vh',
    backgroundColor: ColorConstant.WHITE,
    [theme.breakpoints.down('md')]: {
        fontSize: 'large'
    },
    [theme.breakpoints.up('md')]: {
        fontSize: 'larger'
    },
    wordWrap: 'break-word',
}));