import { Metric, onCLS, onFCP, onINP, onLCP, onTTFB } from 'web-vitals';

type ReportHandler = (metric: Metric) => void;

const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
  if (onPerfEntry && typeof onPerfEntry === 'function') {
    onCLS(onPerfEntry);  // Collect Cumulative Layout Shift
    onFCP(onPerfEntry);  // Collect First Contentful Paint
    onLCP(onPerfEntry);  // Collect Largest Contentful Paint
    onTTFB(onPerfEntry); // Collect Time to First Byte
    onINP(onPerfEntry);  // Collect Interaction to Next Paint (replacement for FID)
  }
};

export default reportWebVitals;
