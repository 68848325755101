/* MatchingContainer.tsx */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Strings } from '../../../../constants/StringConstant';
import { MatchTitle } from '../../../../enum';
import { padDigitsInPrefix } from '../../../../helpers/CommonHelper';
import { isEqualIgnoreCase } from '../../../../helpers/StringHelper';
import { validateDOB } from '../../../../helpers/ValidationHelper';
import { HomeViewModel } from '../../../../viewModels/HomeViewModel';
import { MatchingContainerViewModel } from '../../../../viewModels/MatchingContainerViewModel';
import { MatchingViewModel } from '../../../../viewModels/MatchingViewModel';
import HeaderQuestion from '../../commonViews/HeaderQuestion';
import { HeaderQuestionContainer, MatchingContainer, MatchingInnerContainer } from '../../styles/StylesMatchingScreen';
import CharacterGridComponent from './CharacterGridComponent';
import DayGridComponent from './DayGridComponent';
import DateComponent from './dob/DateComponent';
import GenderComponent from './GenderComponent';
import MonthGridComponent from './MonthGridComponent';
import PostalCodeComponent from './PostalCodeComponent';
import YearGridComponent from './YearGridComponent';

export interface MatchingComponents {
    date: string;
    day: string;
    month: string;
    year: string;
    gender: string;
    surNameFirstLetter: string;
    postalCode: string;
}

interface MatchingContainerScreenProps {
    matchingComponents: MatchingComponents;
    matchTitles: string[];
    showPostalCodes: boolean;
    postalCodes: string[];
    onClickPostCode: (matchingFields: MatchingComponents) => void;
    onCompletionMatchingContainer: (matchingFields: MatchingComponents) => void;
    currentIndex: number;
    setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
    onNoneOfTheAboveClickOnPostCode: (matchingFields: MatchingComponents) => void;
}

const MatchingContainerScreen: React.FC<MatchingContainerScreenProps> = (props: MatchingContainerScreenProps) => {
    const { t } = useTranslation();
    const years = MatchingViewModel().getYears();

    const {
        matchingComponents,
        setMatchingComponents,
        currentMatchTitle,
        headerText,
        subHeaderText,
        updateCurrentMatchTitle,
        updateMatchingComponent,
        updateHeaderForPostalCode,
        updateHeaderForMatchTitle,
    } = MatchingContainerViewModel();

    useEffect(() => {
        updateCurrentMatchTitle(props.matchTitles, props.currentIndex);
    }, [props.currentIndex, props.matchTitles]);

    useEffect(() => {
        setMatchingComponents(props.matchingComponents);
    }, [props.matchingComponents]);

    useEffect(() => {
        if (props.showPostalCodes) {
            updateHeaderForPostalCode();
        } else if (currentMatchTitle) {
            updateHeaderForMatchTitle(currentMatchTitle);
        }
    }, [currentMatchTitle, props.showPostalCodes]);

    const doUpdateMatchingComponent = (key: keyof MatchingComponents, value: string) => {
        updateMatchingComponent(key, props.currentIndex, value, props.matchTitles, props.onCompletionMatchingContainer, props.setCurrentIndex);
    };

    return (
        <MatchingContainer data-testid="MatchingContainer">
            <HeaderQuestionContainer>
                <HeaderQuestion headerText={t(headerText)} subHeaderText={t(subHeaderText)} />
            </HeaderQuestionContainer>
            <MatchingInnerContainer>
                {HomeViewModel().showDate(currentMatchTitle) && !props.showPostalCodes ? (
                    <DateComponent
                        onNextClick={(date) => {
                            if (validateDOB(date)) {
                                doUpdateMatchingComponent('date', date);
                            }
                        }}
                        dateComponent={MatchingViewModel().constructDateComponent(matchingComponents.date, '/')}
                    />
                ) : null}

                {HomeViewModel().showSurName(currentMatchTitle) && !props.showPostalCodes ? (
                    <CharacterGridComponent
                        onClick={(surNameFirstLetter) => doUpdateMatchingComponent('surNameFirstLetter', surNameFirstLetter)}
                        gridAlignment="left"
                    />
                ) : null}

                {isEqualIgnoreCase(currentMatchTitle, MatchTitle.Day) && !props.showPostalCodes ? (
                    <DayGridComponent
                        gridAlignment="left"
                        onDayClick={(day) => doUpdateMatchingComponent('day', day)}
                    />
                ) : null}

                {isEqualIgnoreCase(currentMatchTitle, MatchTitle.Month) && !props.showPostalCodes ? (
                    <MonthGridComponent
                        gridAlignment="left"
                        onMonthClick={(month) => doUpdateMatchingComponent('month', padDigitsInPrefix(month, 2))}
                    />
                ) : null}

                {isEqualIgnoreCase(currentMatchTitle, MatchTitle.Year) && !props.showPostalCodes ? (
                    <YearGridComponent
                        years={years}
                        onYearClick={(selectedIndex) => doUpdateMatchingComponent('year', years[selectedIndex])}
                        onNoneOfTheAboveClick={() => doUpdateMatchingComponent('year', '')}
                        footerText={t(Strings.Matching.NoneOfTheAbove)}
                        gridAlignment="left"
                    />
                ) : null}

                {isEqualIgnoreCase(currentMatchTitle, MatchTitle.Gender) && !props.showPostalCodes ? (
                    <GenderComponent
                        onGenderClick={(gender) => doUpdateMatchingComponent('gender', gender)}
                        gridAlignment="center"
                        declineText={t(Strings.Matching.Gender.RatherNotSay)}
                    />
                ) : null}

                {props.showPostalCodes && props.postalCodes.length > 0 ? (
                    <PostalCodeComponent
                        gridAlignment="left"
                        onClick={(postalCode) => {
                            props.onClickPostCode({ ...matchingComponents, postalCode });
                        }}
                        postalCodes={props.postalCodes}
                        footerText={t(Strings.Matching.NoneOfTheAbove)}
                        onNoneOfTheAboveClick={() => props.onNoneOfTheAboveClickOnPostCode({ ...matchingComponents, postalCode: '' })}
                    />
                ) : null}
            </MatchingInnerContainer>
        </MatchingContainer>
    );
};

export default MatchingContainerScreen;
