
import { jwtDecode } from "jwt-decode";
import { JwtPayload } from "./apiResultModels/TokenResult";

export function getAdminTokenResult(accessToken: string): Promise<JwtPayload> {
    return new Promise((resolve, reject) => {
        try {
            const decodedToken: JwtPayload = jwtDecode(accessToken);
            resolve(decodedToken)
        } catch (error) {
            reject(error as Error)
        }
    })
}
