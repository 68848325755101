import { Grid } from '@mui/material';
import { OrganizationUIEntity } from '../../../../models/OrganizationUIEntity';
import { LargeGridButton } from '../../styles/StylesBookAppointment';
import { OrganisationListGridContainer } from './StylesOrganisation';

interface OrgListGridViewProps {
    organisations: OrganizationUIEntity[];
    handleOrgSelection: (organisation: OrganizationUIEntity) => void
}

const OrganisationListGridView: React.FC<OrgListGridViewProps> = (props: OrgListGridViewProps) => {
    return (
        <OrganisationListGridContainer data-testid="OrganisationGridContainer">
            <Grid container columns={2} justifyContent={'center'}>
                {props.organisations.map((organisation, index) => {
                    return (
                        <Grid item key={`${index}.${organisation.id}`}>
                            <LargeGridButton
                                data-testid="OrganisationButton"
                                onClick={() => props.handleOrgSelection(organisation)}
                                variant="contained"
                            >
                                {organisation.name}
                            </LargeGridButton>
                        </Grid>
                    )
                })}
            </Grid>
        </OrganisationListGridContainer>
    );
};

export default OrganisationListGridView