/* DateComponent.tsx */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonText } from '../../../../../constants/StringConstant';
import { DateType } from '../../../../../enum';
import { removeLastCharacter } from '../../../../../helpers/CommonHelper';
import { isEmpty, isEqualIgnoreCase, isNotEqualIgnoreCase } from '../../../../../helpers/StringHelper';
import { isValidDate, isValidDayOnly, isValidYear, validateDOB } from '../../../../../helpers/ValidationHelper';
import { DateComponentContainer, DateComponentView } from '../../../styles/StylesMatchingScreen';
import DateErrorValidationView from './DateErrorValidationView';
import DateSelectorComponent, { DateComponentSelected } from './DateSelectorComponent';
import KeyboardComponent from './KeyboardComponent';

interface DateComponentProps {
    dateComponent: DateComponents,
    onNextClick: (date: string) => void
}

export interface DateComponents {
    date: string,
    month: string,
    year: string,
}

const DateComponent: React.FC<DateComponentProps> = (props: DateComponentProps) => {
    const { t } = useTranslation();
    const [dateComponent, setDateComponent] = React.useState<DateComponents>(props.dateComponent)
    const [dateComponentSelected, setDateComponentSelected] = React.useState<DateComponentSelected>({ isDateSelected: isNaN(parseInt(props.dateComponent.date)), isMonthSelected: false, isYearSelected: !isNaN(parseInt(props.dateComponent.year)) })
    const [enableDeleteButton, setEnableDeleteButton] = React.useState<boolean>(false)
    const [enableNextButton, setEnableNextButton] = React.useState<boolean>(false)
    const [showValidationError, setShowValidationError] = React.useState<boolean>(false)

    React.useEffect(() => {
        setEnableDeleteButton(isNotEqualIgnoreCase(dateComponent.date, DateType.DD))
    }, [dateComponent.date, dateComponentSelected])

    React.useEffect(() => {
        setShowValidationError(false)
        const isDateValid = isValidDate(dateComponent.date, dateComponent.month, dateComponent.year)
        setEnableNextButton(isDateValid)
        if (dateComponent.year.length === 4 && dateComponent.year !== DateType.YYYY) {
            setShowValidationError(!validateDOB(`${dateComponent.date}.${dateComponent.month}.${dateComponent.year}`))
        }
    }, [dateComponent.date, dateComponent.month, dateComponent.year])

    function resetDateComponentSelection(): { isYearSelected: boolean; isMonthSelected: boolean; isDateSelected: boolean; } {
        return { isYearSelected: false, isMonthSelected: false, isDateSelected: false };
    }

    function handleOnDateChange(data: number) {
        let date = isEqualIgnoreCase(dateComponent.date, DateType.DD) ? `${data}` : `${dateComponent.date}${data}`;
        if ((isEqualIgnoreCase(date, "00"))) {
            return;
        }
        if (date.length === 1 && parseInt(date) > 3) {
            return;
        }
        if (isValidDayOnly(date)) {
            setDateComponent({ ...dateComponent, date: date });
            if (date.length === 2) {
                setDateComponentSelected({ ...resetDateComponentSelection(), isMonthSelected: true });
            }
        }
    }

    function handleOnMonthChange(data: number) {
        let month = isEqualIgnoreCase(dateComponent.month, DateType.MM) ? `${data}` : `${dateComponent.month}${data}`;
        if ((isEqualIgnoreCase(month, "00"))) {
            return;
        }
        if (month.length === 1 && parseInt(month) > 1) {
            return;
        }
        if (parseInt(month) <= 12) {
            setDateComponent({ ...dateComponent, month: month });
            if (month.length === 2 && parseInt(month) > 0) {
                setDateComponentSelected({ ...resetDateComponentSelection(), isYearSelected: true });
            }
        }
    }

    function handleOnYearChange(data: number) {
        if (isEqualIgnoreCase(dateComponent.year, DateType.YYYY) && data === 0) {
            return;
        }
        let year = isEqualIgnoreCase(dateComponent.year, DateType.YYYY) ? `${data}` : `${dateComponent.year}${data}`;
        if (isValidYear(year)) {
            setDateComponent({ ...dateComponent, year: year });
        }
    }

    function onNumberClick(number: number) {
        if (dateComponentSelected.isDateSelected) {
            handleOnDateChange(number);
        } else if (dateComponentSelected.isMonthSelected) {
            handleOnMonthChange(number);
        } else if (dateComponentSelected.isYearSelected) {
            handleOnYearChange(number);
        }
    }

    function onDeleteClick() {
        if (dateComponentSelected.isDateSelected) {
            handleDayDelete(dateComponent);
        } else if (dateComponentSelected.isMonthSelected) {
            handleMonthDelete();
        } else if (dateComponentSelected.isYearSelected) {
            handleYearDelete();
        }
    }

    function handleDayDelete(dateComponent: DateComponents) {
        const updatedDate = removeLastCharacter(dateComponent.date);
        setDateComponent({ ...dateComponent, date: isEmpty(updatedDate) || parseInt(updatedDate) === 0 ? DateType.DD : updatedDate });
    }

    function handleMonthDelete() {
        if (dateComponent.month === DateType.MM && dateComponent.date !== DateType.DD) {
            const updatedDate = removeLastCharacter(dateComponent.date);
            setDateComponent({ ...dateComponent, date: updatedDate });
            setDateComponentSelected({ ...resetDateComponentSelection(), isDateSelected: true });
            return;
        }
        let updatedMonth = removeLastCharacter(dateComponent.month);
        updatedMonth = isEmpty(updatedMonth) || isNaN(parseInt(updatedMonth)) ? DateType.MM : updatedMonth;
        setDateComponent({ ...dateComponent, month: updatedMonth });
    }

    function handleYearDelete() {
        if (dateComponent.year === DateType.YYYY && dateComponent.month !== DateType.MM) {
            const updatedMonth = removeLastCharacter(dateComponent.month);
            setDateComponent({ ...dateComponent, month: updatedMonth });
            setDateComponentSelected({ ...resetDateComponentSelection(), isMonthSelected: true });
            return;
        }
        let updatedYear = removeLastCharacter(dateComponent.year);
        updatedYear = isEmpty(updatedYear) || isNaN(parseInt(updatedYear)) ? DateType.YYYY : updatedYear;
        setDateComponent({ ...dateComponent, year: updatedYear });
    }

    return (
        <DateComponentContainer>
            <DateSelectorComponent
                dateComponent={dateComponent}
                dateComponentSelected={dateComponentSelected}
            />
            <DateErrorValidationView showValidationError={showValidationError} />
            <DateComponentView>
                <KeyboardComponent
                    onNumberClick={onNumberClick}
                    onDeleteClick={onDeleteClick}
                    onNextClick={() => {
                        if (enableNextButton) {
                            props.onNextClick(`${dateComponent.date}/${dateComponent.month}/${dateComponent.year}`)
                        }
                    }}
                    enableDeleteButton={enableDeleteButton}
                    enableNextButton={enableNextButton}
                    nextText={t(ButtonText.Next)}
                    nextTextColor={'white'}
                />
            </DateComponentView>
        </DateComponentContainer>
    );
}

export default DateComponent;
