import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColorConstant } from '../../../../constants/ColorConstant';
import { ButtonText, Strings } from '../../../../constants/StringConstant';
import { removeLastCharacter } from '../../../../helpers/CommonHelper';
import { isEmpty, isNonEmpty } from '../../../../helpers/StringHelper';
import { AppConfig } from '../../../../utils/AppConfig';
import KeyboardComponent from '../matchingScreen/dob/KeyboardComponent';
import { AdminPinContainerView, EnterPinText, KeyboardContainer, PinView } from './StylesAdminScreen';

export interface AdminPinViewProps {
    onCompletePinEntry: (pin: string) => void;
}

const AdminPinView: React.FC<AdminPinViewProps> = ({ onCompletePinEntry }) => {
    const { t } = useTranslation();
    const [pin, setPin] = React.useState<string>('');

    const handleDeleteClick = () => setPin((prevPin) => removeLastCharacter(prevPin));
    const handleNextClick = () => onCompletePinEntry(pin);
    const handleNumberClick = (number: number) => {
        setPin((prevPin) => (prevPin.length < AppConfig.defaultValues.AdminPinLength ? `${prevPin}${number}` : prevPin));
    };

    return (
        <AdminPinContainerView data-testid="AdminPinContainerView">
            <EnterPinText data-testid="EnterPinText">
                {t(Strings.Admin.EnterYourPinToLogIn)}
            </EnterPinText>
            <KeyboardContainer>
                <PinView
                    data-testid="PinView"
                    style={{ color: isEmpty(pin) ? ColorConstant.Divider : ColorConstant.EMERALD_STONE }}
                >
                    {"*".repeat(pin.length === 0 ? 4 : pin.length)}
                </PinView>
                <KeyboardComponent
                    onNumberClick={handleNumberClick}
                    onDeleteClick={handleDeleteClick}
                    onNextClick={handleNextClick}
                    enableDeleteButton={isNonEmpty(pin)}
                    enableNextButton={isNonEmpty(pin)}
                    nextText={ButtonText.Done}
                    nextTextColor="white"
                />
            </KeyboardContainer>
        </AdminPinContainerView>
    );
};

export default AdminPinView;
