/* BookAppointmentCalendarView */

import { Grid } from "@mui/material"
import { formatDate } from "../../../../../helpers/DateTimeHelper"
import { BookAppointmentViewModel } from "../../../../../viewModels/BookAppointmentViewModel"
import { BookAppointmentCalendarDayButton, BookAppointmentCalendarDayGridContainer, BookAppointmentCalendarDayNameContainer, BookAppointmentCalendarDayNameText, BookAppointmentCalendarScreenDayGridContainer } from "../../../styles/StylesBookAppointment"
import { AppointmentsByDate } from "../BookAppointmentContainer"

const gridItemSize = 'calc((100% - 12px) / 7)'

interface BookAppointmentCalendarViewProps {
    currentDate: Date,
    handleDateSelection: (selectedDate: Date) => void,
    monthWiseSessionsByDate: AppointmentsByDate | undefined
}

const BookAppointmentCalendarView: React.FC<BookAppointmentCalendarViewProps> = (props: BookAppointmentCalendarViewProps) => {
    const allDatesOfMonth = BookAppointmentViewModel().getAllDatesOfMonthFromGivenDate(props.currentDate)
    const allDatesAppointmentsOfMonth = props.monthWiseSessionsByDate ? BookAppointmentViewModel().getCalendarComponent(allDatesOfMonth, props.monthWiseSessionsByDate) : []
    const allDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    return (
        <BookAppointmentCalendarScreenDayGridContainer>
            <BookAppointmentCalendarDayNameContainer>
                <Grid container spacing={2} marginLeft={'10%'} marginRight={'10%'}>
                    {allDays.map((day, colIndex) => {
                        return (
                            <Grid item key={`${colIndex}.${day}`} style={{ width: gridItemSize, height: gridItemSize }}>
                                <BookAppointmentCalendarDayNameText>
                                    {day}
                                </BookAppointmentCalendarDayNameText>
                            </Grid>
                        )
                    })}
                </Grid>
            </BookAppointmentCalendarDayNameContainer>
            <BookAppointmentCalendarDayGridContainer>
                <Grid container spacing={2} marginLeft={'10%'} marginRight={'10%'}>
                    {[...Array(7)].map((_, rowIndex) => (
                        <Grid item xs={12} key={`${rowIndex.toString()}.0`}>
                            <Grid container spacing={2}>
                                {[...Array(7)].map((_, colIndex) => {
                                    let text = ''
                                    const calendarComponent = allDatesAppointmentsOfMonth[rowIndex * 7 + colIndex - 7]
                                    if (calendarComponent) {
                                        text = formatDate(calendarComponent.date, "D")
                                    } else {
                                        return <Grid item style={{ width: gridItemSize, height: gridItemSize }} key={`${rowIndex}.${colIndex}`}></Grid>;
                                    }
                                    return (
                                        <Grid item key={`${rowIndex}.${colIndex}`} style={{ width: gridItemSize, height: gridItemSize }}>
                                            <BookAppointmentCalendarDayButton
                                                variant="contained"
                                                disabled={!calendarComponent.hasAppointment}
                                                onClick={() => {
                                                    if (calendarComponent.hasAppointment) {
                                                        props.handleDateSelection(calendarComponent.date)
                                                    }
                                                }}
                                                style={{
                                                    ...BookAppointmentViewModel().getCalendarButtonStyle(calendarComponent.hasAppointment, calendarComponent.isTodayDate),
                                                }}
                                            >
                                                {text}
                                            </BookAppointmentCalendarDayButton>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </BookAppointmentCalendarDayGridContainer>
        </BookAppointmentCalendarScreenDayGridContainer>
    )
}

export default BookAppointmentCalendarView