import { useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonText } from '../../../constants/StringConstant';
import { getLanguageText, handleHomeClick } from '../../../helpers/CommonHelper';
import { isNonEmpty } from '../../../helpers/StringHelper';
import { RouterName } from '../../../navigation/RouterName';
import { setShowLanguageScreen } from '../../../redux/actions/GlobalAction';
import { useAppDispatch, useAppSelector } from '../../../redux/Store';
import { HomeViewModel } from '../../../viewModels/HomeViewModel';
import {
    NavBarBackButton,
    NavBarBackDiv,
    NavBarBackText,
    NavBarDateText,
    NavBarDateTimeSection,
    NavBarHomeButton,
    NavBarLanguageButton,
    NavBarLanguageText,
    NavBarTimeOutText,
    NavBarTimeText,
    StyledAppBar,
    StyledChevronLeftIconIcon,
    StyledHomeIcon,
    StyledLanguageDiv,
    StyledLanguageEmptyDiv,
    StyledLanguageIcon
} from '../styles/StylesCommonView';

export interface TopNavigatorProps {
    isShowHomeButton: boolean;
    isShowBackOption: boolean;
    isShowLanguageOption?: boolean;
    handleBackClick: () => void;
    timeOutMessage: string;
}

const NavigationBarHomeButton: React.FC<{ isMobile: boolean; isPortrait: boolean; onClickHomeButton: () => void }> = ({
    isMobile,
    isPortrait,
    onClickHomeButton
}) => {
    return (
        <NavBarHomeButton
            data-testid="NavBarHomeButton"
            style={{ marginRight: isMobile || isPortrait ? 1 : 5, ...(isMobile ? { height: 40 } : { height: 'auto' }) }}
            onClick={onClickHomeButton}
        >
            <StyledHomeIcon style={{ fontSize: isMobile ? 25 : 40 }} />
        </NavBarHomeButton>
    );
};

const NavigationBarBackButton: React.FC<{ isMobile: boolean; isPortrait: boolean; onClickBackButton: () => void }> = ({
    isMobile,
    isPortrait,
    onClickBackButton
}) => {
    const { t } = useTranslation();
    return (
        <NavBarBackButton
            data-testid="NavBarBackButton"
            style={{
                ...(isMobile || isPortrait ? { aspectRatio: '1 / 1' } : { width: 'auto' }),
                ...(isMobile ? { height: 40 } : { height: 'auto' })
            }}
            onClick={onClickBackButton}
        >
            <StyledChevronLeftIconIcon style={{ fontSize: isMobile ? 35 : 50 }} />
            {!(isMobile || isPortrait) ? <NavBarBackText variant="button">{t(ButtonText.Back)}</NavBarBackText> : null}
        </NavBarBackButton>
    );
};

const NavigationBarLanguageButton: React.FC<{
    isMobile: boolean;
    isPortrait: boolean;
    handleLanguageSelection: () => void;
    langText: string;
}> = ({ isMobile, isPortrait, handleLanguageSelection, langText }) => {
    return (
        <NavBarLanguageButton
            data-testid="NavBarLanguageButton"
            style={{
                ...(isMobile ? { height: 40 } : { height: 'auto' }),
                ...(isMobile ? { alignSelf: 'center' } : { alignSelf: 'auto' })
            }}
            onClick={handleLanguageSelection}
        >
            <StyledLanguageIcon
                style={{
                    fontSize: isMobile ? 25 : 30,
                    marginLeft: isMobile || isPortrait ? '0px' : '8px'
                }}
            />
            <NavBarLanguageText data-testid="NavBarLanguageText" isMobile={isMobile}>
                {langText}
            </NavBarLanguageText>
        </NavBarLanguageButton>
    );
};

const TopBar: React.FC<TopNavigatorProps> = (props: TopNavigatorProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isPortrait = useMediaQuery('(orientation: portrait)');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const currentDate = useAppSelector((state) => state.globalSlice.currentDate);
    const currentTime = useAppSelector((state) => state.globalSlice.currentTime);
    const selectedLanguage = useAppSelector((state) => state.globalSlice.selectedLanguage) ?? HomeViewModel().getLanguages()[0];
    const [tapCount, setTapCount] = useState(0);
    const [lastTapTime, setLastTapTime] = useState<number | null>(null);
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const timeGapBetweenTap = 1000;
    const numberOfTaps = 2;
    const langText = getLanguageText(selectedLanguage?.languageName);
    const navBarDateTextFontSize = HomeViewModel().navBarDateTextFontSize(isMobile, isPortrait);

    const handleAdminAction = useCallback(() => {
        navigate(RouterName.Admin);
        setTapCount(0);
        setLastTapTime(null);
    }, [navigate]);

    const handleTap = () => {
        const currentTime = Date.now();
        if (lastTapTime && currentTime - lastTapTime > timeGapBetweenTap) {
            setTapCount(1);
        } else if (tapCount + 1 === numberOfTaps) {
            if (![`${RouterName.KioskList}`].includes(window.location.pathname)) {
                handleAdminAction();
            }
        } else {
            setTapCount((prev) => prev + 1);
        }
        setLastTapTime(currentTime);

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            setTapCount(0);
            setLastTapTime(null);
        }, timeGapBetweenTap);
    };

    function onClickHomeButton() {
        handleHomeClick(dispatch, navigate);
    }

    return (
        <StyledAppBar data-testid="TopBarContainer" mobile={isMobile}>
            <NavBarTimeOutText data-testid="NavBarTimeOutText">{props.timeOutMessage ?? ''}</NavBarTimeOutText>
            <NavBarDateTimeSection data-testid="NavBarDateTimeSection" mobile={isMobile} portrait={isPortrait} onClick={handleTap}>
                <NavBarDateText data-testid="NavBarDateText" style={{ fontSize: navBarDateTextFontSize }}>
                    {currentDate}
                </NavBarDateText>
                <NavBarTimeText data-testid="NavBarTimeText" mobile={isMobile} portrait={isPortrait} style={{ fontSize: navBarDateTextFontSize }}>
                    {currentTime}
                </NavBarTimeText>
            </NavBarDateTimeSection>

            <NavBarBackDiv mobile={isMobile}>
                {props.isShowHomeButton ? (
                    <NavigationBarHomeButton isMobile={isMobile} isPortrait={isPortrait} onClickHomeButton={onClickHomeButton} />
                ) : null}
                {props.isShowBackOption ? (
                    <NavigationBarBackButton isMobile={isMobile} isPortrait={isPortrait} onClickBackButton={props.handleBackClick} />
                ) : null}
            </NavBarBackDiv>

            {(props.isShowLanguageOption ?? true) && isNonEmpty(langText?.trim()) ? (
                <StyledLanguageDiv mobile={isMobile}>
                    <NavigationBarLanguageButton
                        isMobile={isMobile}
                        isPortrait={isPortrait}
                        handleLanguageSelection={() => dispatch(setShowLanguageScreen(true))}
                        langText={langText}
                    />
                </StyledLanguageDiv>
            ) : (
                <StyledLanguageEmptyDiv mobile={isMobile} />
            )}
        </StyledAppBar>
    );
};

export default TopBar;
