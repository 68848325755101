import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export class AnalyticsManager {
    public static readonly initializeDataDog = () => {
        const DDApplicationID = process.env.REACT_APP_DATADOG_APPLICATIONID as string;  
        const DDEnv = process.env.REACT_APP_DD_ENV as string;
        const clientToken = process.env.REACT_APP_DATADOG_CLIENTTOKEN as string;
        datadogRum.init({
            applicationId: DDApplicationID,
            clientToken: clientToken,
            site: 'datadoghq.eu',
            service: 'automated-arrivals-kiosk-app',
            env: DDEnv,
            // Specify a version number to identify the deployed version of your application in Datadog
            // version: '1.0.0',
            sessionSampleRate: 100,
            startSessionReplayRecordingManually: true,
            trackUserInteractions: false,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
        });
        datadogLogs.init({
            clientToken: clientToken,
            site: 'datadoghq.eu',
            forwardErrorsToLogs: false,
            sessionSampleRate: 100,
            service: 'automated-arrivals-kiosk-app',
          })
    };
    
    public static readonly setGlobalContextForSession = (propertyKey: string, value:any) => {
        datadogRum.setGlobalContextProperty(propertyKey, value);
    };

    public static readonly logDDError = (errorLog: string, params: any) => {
        datadogLogs.logger.error(errorLog, params);
    }

}