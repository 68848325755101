import { styled } from "@mui/material";
import { ColorConstant } from "../../../../constants/ColorConstant";

export const OrganisationListContainerView = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ColorConstant.WHITE,
    height: '100vh',
    width: '100vw',
    userSelect: 'none'
});

export const OrganisationView = styled('div')({
    display: 'flex', 
    flex: 1, 
    flexDirection: 'column',
    overflowY: 'hidden'
});

export const OrganisationListGridContainer = styled('div')({
    display: 'flex',
    margin: 20,
    overflowY: 'auto',
});