/* PatientDemographicScreen.tsx */

import React from "react";
import { Patient } from "../../../../../api/apiResultModels/BookedPatientResult";
import { PatientDemographicScreenContainer } from "../../../styles/StylesCheckIn";
import PatientInfoAlertView from "./PatientInfoAlertView";
import PatientInfoView from "./patientInfoView/PatientInfoView";

export interface PatientDemographicScreenProps {
    patientInfo: Patient | undefined;
    handleYesAction: () => void
}

const PatientDemographicScreen: React.FC<PatientDemographicScreenProps> = (props: PatientDemographicScreenProps) => {
    const [showDetailsNotUpdateAlert, setShowDetailsNotUpdateAlert] = React.useState<boolean>(false)

    return (
        <PatientDemographicScreenContainer>
            {showDetailsNotUpdateAlert ?
                <PatientInfoAlertView handleContinueCheckInAction={props.handleYesAction} />
                :
                <PatientInfoView
                    patientInfo={props.patientInfo}
                    handleYesAction={props.handleYesAction}
                    handleNoAction={() => setShowDetailsNotUpdateAlert(true)}
                />
            }
        </PatientDemographicScreenContainer>
    )
}

export default PatientDemographicScreen