import { SurveyAnswer } from "../api/apiParameterModels/PostAnonymousSurveyParameters";
import { NonAnonymousSurveyFrequencyData, PostNonAnonymousSurveyFrequencyParameters } from "../api/apiParameterModels/PostNonAnonymousSurveyFrequencyParameters";
import { NonAnonymousSurveyAnswerOption, NonAnonymousSurveyQuestion } from "../api/apiParameterModels/PostNonAnonymousSurveyParameters";
import { QuestionAnswerOption, SurveyQuestion, } from "../api/apiResultModels/SurveyResult";
import { Strings } from "../constants/StringConstant";
import { APIDataType } from "../enum";
import { isEmpty, isEqualIgnoreCase, isNonEmpty } from "../helpers/StringHelper";

export const SurveyViewModel = () => {

    function getAnswerText(currentQuestion: SurveyQuestion, surveyAnswers: SurveyAnswer[]): string {
        const surveyAnswer = surveyAnswers.find(surveyAnswer => surveyAnswer.attributes.refQuestionId === currentQuestion.questionId);
        if (surveyAnswer?.attributes.answerText) {
            return isEqualIgnoreCase(surveyAnswer.attributes.answerText, Strings.Questionnaire.Skipped) ? '' : surveyAnswer.attributes.answerText
        } else {
            return ''
        }
    }

    function getSelectedOptionId(currentQuestion: SurveyQuestion, surveyAnswers: SurveyAnswer[]): string | undefined {
        const surveyAnswer = surveyAnswers.find(surveyAnswer => surveyAnswer.attributes.refQuestionId === currentQuestion.questionId);
        if (surveyAnswer?.attributes.refOptionId) {
            return surveyAnswer.attributes.refOptionId
        } else {
            return undefined
        }
    }

    const upsertAnswer = (newSurveyAnswer: SurveyAnswer, surveyAnswers: SurveyAnswer[]) => {
        const updatedAnswers: SurveyAnswer[] = surveyAnswers.map(surveyAnswer =>
            surveyAnswer.attributes.refQuestionId ===
                newSurveyAnswer.attributes.refQuestionId ?
                {
                    ...surveyAnswer,
                    attributes: {
                        ...surveyAnswer.attributes,
                        refOptionId: newSurveyAnswer.attributes.refOptionId,
                        answerText: newSurveyAnswer.attributes.answerText
                    }
                }
                : surveyAnswer
        );
        const surveyExists = surveyAnswers.find(surveyAnswer => surveyAnswer.attributes.refQuestionId === newSurveyAnswer.attributes.refQuestionId);
        const updatedSurveyAnswers = surveyExists ? updatedAnswers : [...updatedAnswers, newSurveyAnswer];
        return updatedSurveyAnswers
    };

    function mapToNonAnonymousSurveyParameters(surveyAnswers: SurveyAnswer[]) {
        let surveyQuestions: NonAnonymousSurveyQuestion[] = []
        const optionTypeSurveyAnswers = surveyAnswers.filter(surveyAnswer => isNonEmpty(surveyAnswer.attributes.refOptionId))
        const textTypeSurveyAnswers = surveyAnswers.filter(surveyAnswer => isEmpty(surveyAnswer.attributes.refOptionId))

        for (const optionTypeSurveyAnswer of optionTypeSurveyAnswers) {
            let answerOption: NonAnonymousSurveyAnswerOption = {
                IsChecked: true,
                AnswerOptionText: optionTypeSurveyAnswer.attributes.answerText,
                QuestionText: optionTypeSurveyAnswer.attributes.questionText,
            }
            if ((optionTypeSurveyAnswer.attributes.snomedCode ?? 0) > 0) {
                answerOption = { ...answerOption, SnomedCode: optionTypeSurveyAnswer.attributes.snomedCode }
            } else if (optionTypeSurveyAnswer.attributes.optionCode && isNonEmpty(optionTypeSurveyAnswer.attributes.optionCode)) {
                answerOption = { ...answerOption, OptionCode: parseInt(optionTypeSurveyAnswer.attributes.optionCode) }
            }
            surveyQuestions.push({ AnswerOptions: [answerOption] })
        }

        for (const textTypeSurveyAnswer of textTypeSurveyAnswers) {
            let nonAnonymousSurveyQuestion: NonAnonymousSurveyQuestion = {
                QuestionText: textTypeSurveyAnswer.attributes.questionText,
                QuestionCode: textTypeSurveyAnswer.attributes.refQuestionId ? `${textTypeSurveyAnswer.attributes.refQuestionId}` : '',
                AnswerControlType: 'TextBox',
                SelectedAnswer: textTypeSurveyAnswer.attributes.answerText
            }
            surveyQuestions.push(nonAnonymousSurveyQuestion)
        }
        return surveyQuestions;
    }

    function mapToNonAnonymousSurveyFrequencyParameters(surveyAnswers: SurveyAnswer[], patientFlowPatientId: number, kioskID?: string): PostNonAnonymousSurveyFrequencyParameters | undefined {
        if (kioskID && isNonEmpty(kioskID)) {
            let nonAnonymousSurveyFrequencyDatas: NonAnonymousSurveyFrequencyData[] = []
            for (const surveyAnswer of surveyAnswers) {
                const nonAnonymousSurveyFrequencyData: NonAnonymousSurveyFrequencyData = {
                    type: APIDataType.NonAnonymousSurveyFrequency,
                    attributes: {
                        kioskId: parseInt(kioskID),
                        questionnaireId: surveyAnswer.attributes.refQuestionnaireId,
                        patientFlowPatientId: patientFlowPatientId
                    }
                }
                nonAnonymousSurveyFrequencyDatas.push(nonAnonymousSurveyFrequencyData)
            }
            return { data: nonAnonymousSurveyFrequencyDatas }
        }
        return undefined
    }

    function getUpdatedRefOptionId(option: QuestionAnswerOption | undefined, skipped: boolean) {
        let refOptionId: string | undefined;
        if (skipped) {
            refOptionId = undefined;
        } else if (option?.optionId) {
            refOptionId = `${option.optionId}`;
        } else {
            refOptionId = undefined;
        }
        return refOptionId
    }

    function getUpdatedAnswerText(text: string, option: QuestionAnswerOption | undefined, skipped: boolean) {
        let answerText: string;
        if (skipped) {
            answerText = "Skipped";
        } else if (option?.optionValue) {
            answerText = option.optionValue;
        } else {
            answerText = text;
        }
        return answerText
    }

    return {
        getAnswerText,
        getSelectedOptionId,
        upsertAnswer,
        mapToNonAnonymousSurveyParameters,
        mapToNonAnonymousSurveyFrequencyParameters,
        getUpdatedRefOptionId,
        getUpdatedAnswerText
    }
}