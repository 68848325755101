import React from "react";
import { Strings } from "../constants/StringConstant";
import { MatchTitle } from "../enum";
import { AppUtil } from "../Util";
import { MatchingComponents } from "../view/web/screens/matchingScreen/MatchingContainerScreen";
import { MatchingViewModel } from "./MatchingViewModel";

type OnCompletionMatchingContainer = (matchingFields: MatchingComponents) => void;
type UpdateCurrentIndex = (index: number) => void;

export const MatchingContainerViewModel = () => {
    const [matchingComponents, setMatchingComponents] = React.useState<MatchingComponents>({
        date: '',
        day: '',
        month: '',
        year: '',
        gender: '',
        surNameFirstLetter: '',
        postalCode: ''
    });
    const [currentMatchTitle, setCurrentMatchTitle] = React.useState<MatchTitle>();
    const [headerText, setHeaderText] = React.useState<string>('');
    const [subHeaderText, setSubHeaderText] = React.useState<string>('');

    const updateCurrentMatchTitle = (matchTitles: string[], currentIndex: number) => {
        const currentMatchTitle = AppUtil.stringToEnum(matchTitles[currentIndex], MatchTitle);
        setCurrentMatchTitle(currentMatchTitle);
    };

    const updateHeaderForPostalCode = () => {
        setHeaderText(Strings.Matching.WhatIsYourPostalCode);
        setSubHeaderText('');
    };

    const updateHeaderForMatchTitle = (currentMatchTitle: MatchTitle) => {
        const bannerComponents = MatchingViewModel().getBannerText(currentMatchTitle);
        setHeaderText(bannerComponents.headerText);
        setSubHeaderText(bannerComponents.subHeaderText);
    };

    const updateMatchingComponent = (
        key: keyof MatchingComponents,
        currentIndex: number,
        value: string,
        matchTitles: string[],
        onCompletionMatchingContainer: OnCompletionMatchingContainer,
        updateCurrentIndex: UpdateCurrentIndex
    ) => {
        const updatedMatchingComponents = { ...matchingComponents, [key]: value };
        const nextIndex = currentIndex + 1;
        if (nextIndex === matchTitles.length) {
            setMatchingComponents(updatedMatchingComponents);
            onCompletionMatchingContainer(updatedMatchingComponents);
        } else {
            const currentMatchTitle = AppUtil.stringToEnum(matchTitles[nextIndex], MatchTitle);
            setCurrentMatchTitle(currentMatchTitle);
            setMatchingComponents(updatedMatchingComponents);
            updateCurrentIndex(nextIndex);
        }
    };

    return {
        matchingComponents,
        setMatchingComponents,
        currentMatchTitle,
        setCurrentMatchTitle,
        headerText, subHeaderText,
        updateCurrentMatchTitle,
        updateHeaderForPostalCode,
        updateHeaderForMatchTitle,
        updateMatchingComponent
    };
};