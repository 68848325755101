// src/authConfig.js

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_B2C_CLIENT_ID as string,
        authority: process.env.REACT_APP_B2C_AUTHORITY_URL as string,
        knownAuthorities: (process.env.REACT_APP_B2C_KNOWN_AUTHORITY_URLS as string ?? "").split(","),
        redirectUri: process.env.REACT_APP_B2C_REDIRECT_URI as string
    },
    cache: {
        cacheLocation: "sessionStorage",  // Can also use localStorage
        storeAuthStateInCookie: false,    // Set to true if you have issues on IE 11 or Edge
    }
};

export const loginRequest = {
    scopes: (process.env.REACT_APP_B2C_SCOPES as string ?? "").split(",")
};
