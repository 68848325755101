import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TokenResult } from '../../api/apiResultModels/TokenResult';
import { DateFormat, getTodayDateTimeString } from '../../helpers/DateTimeHelper';

export type TokenState = {
  accessToken: string;
  tokenCreatedDateTime: string,
  tokenExpiresIn?: number,
  adminAccessToken: string,
  adminTokenCreatedDateTime: Date | null,
  adminTokenExpiresOn: Date | null,
};
const initialState: TokenState = {
  accessToken: '',
  tokenCreatedDateTime: '',
  tokenExpiresIn: undefined,
  adminAccessToken: '',
  adminTokenCreatedDateTime: null,
  adminTokenExpiresOn: null,
};

export const tokenSlice = createSlice({
  name: 'token',
  initialState: initialState,
  reducers: {
    setTokenDetails: (state, action: PayloadAction<TokenResult | undefined>) => {
      state.accessToken = action.payload?.access_token ?? '';
      state.tokenExpiresIn = action.payload?.expires_in;
      state.tokenCreatedDateTime =action.payload?.tokenCreatedDateTime ?? getTodayDateTimeString(DateFormat.FullDateTime);
    },
    setAdminTokenDetails: (state, action: PayloadAction<TokenResult | undefined>) => {
      state.adminAccessToken = action.payload?.access_token ?? '';
      state.adminTokenExpiresOn = action.payload?.adminTokenExpiresOn ? action.payload.adminTokenExpiresOn : null;
      state.adminTokenCreatedDateTime = action.payload?.adminTokenCreatedDateTime ?? new Date();
    },
    resetTokenState: () => {
      return initialState;
    },
    resetClientToken: (state) => {
      state.accessToken = '';
      state.tokenCreatedDateTime = '';
      state.tokenExpiresIn = undefined;
    }
  },
});

export default tokenSlice.reducer;
