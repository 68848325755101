/* StringConstant.ts */

export const Strings = {
    WeAreAskingTheseQuestionsToHelpIdentifyYou: 'We are asking these questions to help identify you.',
    CheckTheInformationYouProvidedAndTryAgain: 'Check the information you provided and try again.',
    IfTheInformationYouProvidedIsCorrectPleaseSpeakToAMemberOfOurReceptionTeam: 'If the information you provided is correct, please speak to a member of our reception team.',
    Hi: 'Hi',
    Thanks: 'Thanks',
    NotYou: 'Not you?',
    optional: 'optional',
    mandatory: 'mandatory',
    TimingOutIn: 'Timing Out In...',
    SelectKIOSK: 'Select KIOSK',
    SelectOrganisation: 'Select Organisation',
    UnsupportedVersion: 'Unsupported version',
    Windows7Unsupported: 'Windows 7 is no longer supported. Please contact your practice admin for assistance.',
    Home: {
        Welcome: 'Welcome!',
        WelcomeTo: 'Welcome to',
        WhereAreYouVisitingToday: 'Where are you visiting today?',
        BookAppointment: 'Book appointment',
        SiteMap: 'Site Map',
        Questionnaires: 'Questionnaires',
    },
    Matching: {
        WhatIsYourDateOfBirth: 'What is your date of birth?',
        DateOfBirthExample: 'e.g., 01/01/1979',
        WhatDayOfTheMonthWereYouBorn: 'What day of the month were you born?',
        WhatMonthWereYouBorn: 'What month were you born?',
        WhatYearWereYouBorn: 'What year were you born?',
        WhatIsYourPostalCode: 'What is your postcode?',
        NoneOfTheAbove: 'None of the above',
        AreYou: 'Are You...',
        WhatIsTheFirstLetterOfYourSurname: 'What is the first letter of your surname?',
        YearNotPresent: 'Year not present',
        Gender: {
            Male: 'Male',
            Female: 'Female',
            Other: 'Other',
            RatherNotSay: 'I\'d rather not say',
            NotDisclosed: 'Not Disclosed',
            Indeterminate: 'Indeterminate',
            Unknown: 'Unknown',
        }
    },
    CheckIn: {
        Check_in: 'Check in',
        appointment: 'appointment',
        appointments: 'appointments',
        for: 'for',
        with: 'with',
        AppointmentColon: 'Appointment: ',
        CheckInForOneAppointment: 'Check in for one appointment',
        CheckInForMoreAppointments: 'Check in for ## appointments',
        IMPORTANT: 'IMPORTANT',
        WeCantFindYourAppointment: 'We can\'t find your appointment',
        SorryLooksLikeSomethingWentWrong: 'Sorry, looks like something went wrong...',
        SorryUnableToProcessYourRequest: 'Sorry! Unable to process your request.',
        PleaseReportToReception: 'Please report to reception',
        SelectAppointments: 'Select the appointments you want to check in for.',
        YouAreTooLateToCheckInForThisAppointment: 'You are too late to check in for this appointment.',
        SpeakToOneOfOurReceptionTeam: 'Speak to one of our reception team.',
        YouAreTooEarlyToCheckInForThisAppointment: 'You are too early to check in for this appointment.',
        YouCanArriveUpToSomeMinutesEarly: 'You can arrive up to ## minutes early.',
        YouHaveCheckedInForYourAppointment: 'You have checked in for your appointment',
        WeWouldLikeToLetYouKnow: 'We would like to let you know:',
        OnTime: 'On time',
        RunningMinutesLate: 'Running ## minutes late',
        TheSystemHasBeenUnableToCheckYouInForThisAppointment: 'The system has been unable to check you in for this appointment.',
        PleaseSpeakToOurReceptionTeamInstead: 'Please speak to our reception team instead.',
        PatientInfo: {
            HereIsThePersonalInformationWeCurrentlyHoldAboutYou: 'Here is the personal information we currently hold about you.',
            endingWith: '(ending with)',
            email: 'Email',
            mobileNumber: 'Mobile number',
            name: 'Name',
            postCode: 'Postcode',
            homeTelephoneNumber: 'Home telephone number',
            NoInformationOnRecord: 'No information on record',
            SomeInformationHasBeenHiddenForPrivacyReasons: 'Some information has been hidden for privacy reasons.',
            AreTheseDetailsUpToDate: 'Are these details up to date?',
            ItIsImportantThatWeHaveUpToDateInformationForYou: 'It is important that we have up to date information for you.',
            PleaseLetTheReceptionTeamKnowThatWeNeedToUpdateYourRecords: 'Please let the reception team know that we need to update your records.',
        },
    },
    BookAppointment: {
        SelectAppointmentType: 'Select appointment type',
        WeCantFindYourDetails: 'We can\'t find your details',
        WhenDoYouWantTheAppointment: 'When do you want the appointment?',
        ChooseSpecificDate: 'Choose specific date',
        InOneWeek: 'In 1 week',
        InTwoWeeks: 'In 2 weeks',
        InOneMonth: 'In 1 month',
        NextAvailableAppointment: 'Next available appointment',
        BookThisAppointment: 'Book this appointment',
        YouHaveBookedYourAppointment: 'You have booked your appointment',
        SelectAppointmentDate: 'Select appointment date',
        Appointments: 'Appointments',
        Today: 'Today',
        NoAppointments: 'No appointments',
        ReasonForAppointment: 'Reason for appointment',
        PleaseEnterTheReasonForAppointment: 'Please enter the reason for appointment',
    },
    Admin: {
        AdminLogin: 'Admin login',
        AdminArea: 'Admin area',
        EnterYourPinToLogIn: 'Enter your pin to log in',
        TechnicalInformation: 'Technical Information',
        CloseSoftware: 'Close Software',
        ChangeKiosk: 'Change Kiosk',
        RebootSystem: 'Reboot system',
        ShutdownSystem: 'Shut down system',
        disclaimer: 'Closing the software will take you back to Windows.',
        IncorrectPin: 'Incorrect pin',
        showOnScreenKeyboard: 'showOnScreenKeyboard',
        showMenuBar: 'showMenuBar',
        hideMenuBar: 'hideMenuBar',
    },
    Questionnaire: {
        SelectQuestionnaire: 'Select a questionnaire',
        ThankYou: 'Thank You!',
        ThankYouYourAnswersHaveBeenSaved: 'Thank you, your answers have been saved.',
        YourFeedbackIsImportantToUs: 'Your feedback is important to us.',
        TheSurgeryWouldLikeToAsk: 'The surgery would like to ask:',
        Skipped: 'Skipped',
    }
} as const;

export const ButtonText = {
    Back: 'Back',
    Next: 'Next',
    Delete: 'Delete',
    Close: 'Close',
    TryAgain: 'Try again',
    CheckIn: 'Check in',
    Done: 'Done',
    Yes: 'Yes',
    No: 'No',
    Ok: 'OK',
    ContinueCheckingIn: 'Continue checking in',
    NoneOfTheAbove: 'None of the above',
    Continue: 'Continue',
    CapsLock: 'Caps Lock',
    Shift: 'Shift',
    CLOSE: 'CLOSE',
    Space: 'Space',
    SkipQuestion: "Skip Question",
} as const;

export const ErrorMessage = {
    PleaseEnterValidDateOfBirth: 'Please enter valid date of birth',
    KioskRegistrationFailed: 'Unable to setup the credentials. Please contact support team.',
    PleaseSpeakToAMemberOfOurReceptionTeam: "Please speak to a member of our reception team.",
    KioskIsDisabledForThisOrgOrDevice: "Kiosk is disabled for this org or device.",
} as const;

export const ImageAltText = {
    KIOSKLogo: 'KIOSK Logo'
} as const;

export const LocalStorageKey = {
    SelectedKiosk: 'SelectedKiosk',
    AllOrgs: 'AllOrgs',
    SelectedOrg: 'SelectedOrg',
    Certificate: 'Certificate',
} as const;


export const ModuleName = {
    CheckIn: 'Check in',
    BookAppointment: 'Book appointment',
    Questionnaires: 'Questionnaires',
    SiteMap: 'Site Map',
} as const;

export const UsageLog = {
    CheckIn: 'Added arrival of a patient',
    BookAppointment: 'Booked Appointment by a patient',
    Survey: 'Survey posted by a patient',
} as const;