import React from "react";
import { Dispatch, UnknownAction } from "redux";
import { apiContainer } from "../api/APIContainer";
import { APIRepoKeys } from "../api/APIRepoKeys";
import { AdminPinValidationResult } from "../api/apiResultModels/AdminPinValidationResult";
import { ICommonRepository } from "../api/interfaces/ICommonRepository";
import { Strings } from "../constants/StringConstant";
import { clearAllAndRefresh, sendUsageLogs } from "../helpers/CommonHelper";
import { resetConfigState } from "../redux/actions/ConfigAction";
import { resetGlobalState, setAPICallInProgress } from "../redux/actions/GlobalAction";
import { resetClientToken } from "../redux/actions/TokenAction";
import { LoginViewModel } from "./LoginViewModel";

export const AdminContainerViewModel = () => {
  const commonAPIRepository = apiContainer.get<ICommonRepository>(
    APIRepoKeys.COMMON_API_REPOSITORY
  );

  const [isShowPinView, setIsShowPinView] = React.useState<boolean>(true);
  const [showValidationMessage, setShowValidationMessage] = React.useState<boolean>(false);
  const [bannerMessage, setBannerMessage] = React.useState<string>(Strings.Admin.AdminLogin);
  const [adminScreenTimeOutMessage, setAdminScreenTimeOutMessage] = React.useState<string>("");
  const [showSomethingWentWrongScreen, setShowSomethingWentWrongScreen] = React.useState(false);

  function validatePin(pin: string, dispatch: Dispatch<UnknownAction>) {
    dispatch(setAPICallInProgress(true));
    commonAPIRepository
      .validateAdminPin(pin)
      .then((response) => {
        const result: AdminPinValidationResult = JSON.parse(response);
        if (result.data?.attributes?.passwordMatched) {
          setIsShowPinView(false);
          setShowValidationMessage(false);
          setBannerMessage(Strings.Admin.AdminArea);
        } else {
          setShowValidationMessage(true);
          setBannerMessage(Strings.Admin.IncorrectPin);
        }
      })
      .catch(() => {
        setShowSomethingWentWrongScreen(true);
      })
      .finally(() => {
        dispatch(setAPICallInProgress(false));
      });
  }

  const setTimeOutMessage = (message: string, timer?: number) => {
    setAdminScreenTimeOutMessage(message);
    if (timer) {
      setTimeout(() => {
        clearTimeOutMessage();
      }, timer);
    }
  };

  const clearTimeOutMessage = () => {
    setAdminScreenTimeOutMessage("");
  };

  const postUsageLogs = async (dispatch: Dispatch<UnknownAction>) => {
    if (sendUsageLogs()) {
      dispatch(setAPICallInProgress(true));
      await commonAPIRepository.postUsageLogs(dispatch);
      dispatch(setAPICallInProgress(false));
    }
  };

  function handleChangeKioskAction(dispatch: Dispatch<UnknownAction>, onChangeSuccess: () => void) {
    if (LoginViewModel().isAdminUserSessionValid()) {
      dispatch(resetClientToken());
      dispatch(resetConfigState());
      dispatch(resetGlobalState());
      localStorage.clear();
      onChangeSuccess();
    } else {
      clearAllAndRefresh();
    }
  }

  return {
    isShowPinView,
    showValidationMessage,
    bannerMessage,
    validatePin,
    adminScreenTimeOutMessage,
    setTimeOutMessage,
    clearTimeOutMessage,
    postUsageLogs,
    handleChangeKioskAction,
    showSomethingWentWrongScreen,
  };
};