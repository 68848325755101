import React from "react";
import { ErrorMessage, LocalStorageKey } from "../constants/StringConstant";
import { getAllOrgs } from "../helpers/CommonHelper";
import { OrganizationUIEntity } from "../models/OrganizationUIEntity";
import { store } from "../redux/Store";
import { AlertContent } from "../view/web/screens/home/HomeScreen";
import { LoginViewModel } from "./LoginViewModel";

export const OrganisationViewModel = () => {

  const [organisations, setOrganisations] = React.useState<OrganizationUIEntity[]>([])
  const [alertContent, setAlertContent] = React.useState<AlertContent | undefined>(undefined)
  const [showAlert, setShowAlert] = React.useState<boolean>(false)

  const getSavedOrganisations = () => {
    const allOrgUIEntities = getAllOrgs()
    return allOrgUIEntities;
  }

  const handleOrgSelection = async (selectedOrg: OrganizationUIEntity, onSelectSuccess: () => void) => {
    const clientSecretAvailable = await LoginViewModel().clientSecretAvailable(selectedOrg)
    if (clientSecretAvailable) {
      localStorage.setItem(LocalStorageKey.SelectedOrg, JSON.stringify(selectedOrg));
      setTimeout(() => {
        onSelectSuccess();
      }, 500);
    } else {
      const kioskName = store.getState().globalSlice.currentKiosk?.name
      const kioskID = store.getState().globalSlice.currentKiosk?.id
      const orgName = selectedOrg.name
      const orgID = selectedOrg.id
      const information = `Kiosk Name: ${kioskName}\nKiosk Id: ${kioskID}\nOrganisation Name: ${orgName}\nOrganisation Id: ${orgID}\n\n${ErrorMessage.KioskRegistrationFailed}`
      setAlertContent({ title: 'Failed', message: information })
      setShowAlert(true)
    }
  }

  const saveOrganisations = (orgs: OrganizationUIEntity[]) => {
    setOrganisations(orgs)
  }

  return {
    handleOrgSelection,
    alertContent,
    showAlert,
    getSavedOrganisations,
    saveOrganisations,
    organisations
  }
}