/* APIFailScreen.tsx */

import React from 'react';
import { APIFailScreenContainerView, APIFailScreenFooterTextView, APIFailScreenInfoText, APIFailScreenTitleText, APIFailScreenTitleView, APIFailureFooterButton, APIFailureFooterButtonView } from '../styles/StylesCommonView';
import { StyledCancelOutlinedIcon } from '../styles/StylesMatchingScreen';

interface APIFailScreenProps {
    title: string
    information: string
    tryAgainAction: () => void
    buttonText: string
}

const APIFailScreen: React.FC<APIFailScreenProps> = (props: APIFailScreenProps) => {
    return (
        <APIFailScreenContainerView>
            <APIFailScreenTitleView>
                <StyledCancelOutlinedIcon data-testid="StyledCancelOutlinedIcon" />
                <APIFailScreenTitleText data-testid="FailureTitleText">{props.title}</APIFailScreenTitleText>
            </APIFailScreenTitleView>
            <APIFailScreenFooterTextView>
                <APIFailScreenInfoText data-testid="APIFailScreenInfoText"> {props.information}</APIFailScreenInfoText>
                <APIFailureFooterButtonView style={{justifyContent: 'center',}}>
                    <APIFailureFooterButton data-testid="APIFailScreenFooterTryAgainButton" onClick={props.tryAgainAction}>{props.buttonText}</APIFailureFooterButton>
                </APIFailureFooterButtonView>
            </APIFailScreenFooterTextView>
        </APIFailScreenContainerView>
    )
}

export default APIFailScreen