/* GlobalAction.ts */

import { globalSlice } from "../reducers/GlobalReducer";

export const {
    setKiosks,
    setCurrentOrg,
    setCurrentKiosk,
    setCurrentDate,
    setCurrentTime,
    setShowLanguageScreen,
    setSelectedLanguage,
    setAPICallInProgress,
    resetGlobalState,
    updateUsageLogs,
    resetUsageLogs,
    setUsageLogLastSentDateTime,
} = globalSlice.actions;
