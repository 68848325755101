import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Strings } from '../../../../constants/StringConstant';
import useIdleTimer from '../../../../customHooks/useIdleTimer';
import { AdminActionType } from '../../../../enum';
import { getScreenTimeOutInMilliSeconds, handleHomeClick } from '../../../../helpers/CommonHelper';
import { RouterName } from '../../../../navigation/RouterName';
import { RootState, store, useAppDispatch } from '../../../../redux/Store';
import { AdminContainerViewModel } from '../../../../viewModels/AdminContainerViewModel';
import Loader from '../../commonViews/Loader';
import MessageBanner from '../../commonViews/MessageBanner';
import SomethingWentWrongScreen from '../../commonViews/SomethingWentWrongScreen';
import TopBar from '../../commonViews/TopBar';
import AdminOptionsScreen from './AdminOptionsScreen';
import AdminPinView from './AdminPinView';
import { AdminContainerScreenView } from './StylesAdminScreen';

const AdminContainerScreen: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const apiCallInProgress = useSelector((state: RootState) => state.globalSlice.apiCallInProgress);
    const screenTimeOut = store.getState().configSlice.kioskDetails?.screenTimeOut;
    const { isIdle, timeRemaining } = useIdleTimer(getScreenTimeOutInMilliSeconds(screenTimeOut), dispatch, navigate);

    const {
        isShowPinView,
        showValidationMessage,
        validatePin,
        bannerMessage,
        adminScreenTimeOutMessage,
        setTimeOutMessage,
        clearTimeOutMessage,
        postUsageLogs,
        handleChangeKioskAction,
        showSomethingWentWrongScreen,
    } = AdminContainerViewModel();

    React.useEffect(() => {
        if (isIdle && timeRemaining === 1000) {
            setTimeOutMessage(`${t(Strings.TimingOutIn)} ${timeRemaining / 1000}`, 1000);
        } else if (isIdle && timeRemaining && timeRemaining <= 3000) {
            setTimeOutMessage(`${t(Strings.TimingOutIn)} ${timeRemaining / 1000}`);
        } else {
            clearTimeOutMessage();
        }
    }, [dispatch, isIdle, navigate, t, timeRemaining, setTimeOutMessage, clearTimeOutMessage]);

    async function handleAdminAction(action: AdminActionType) {
        if (window.electron) {
            postUsageLogs(dispatch);
            switch (action) {
                case AdminActionType.CloseSoftware:
                    window.electron.adminAction(Strings.Admin.CloseSoftware);
                    return;
                case AdminActionType.ChangeKiosk:
                    handleChangeKioskAction(dispatch, () => {
                        setTimeout(() => {
                            navigate(RouterName.SplashScreen);
                        }, 500);
                    });
                    return;
                case AdminActionType.RebootSystem:
                    window.electron.adminAction(Strings.Admin.RebootSystem);
                    return;
                case AdminActionType.ShutdownSystem:
                    window.electron.adminAction(Strings.Admin.ShutdownSystem);
                    return;
            }
        } else {
            console.log("window.electron is undefined");
        }
    }

    return (
        <AdminContainerScreenView>
            <TopBar
                isShowHomeButton={false}
                isShowBackOption={true}
                handleBackClick={() => handleHomeClick(dispatch, navigate)}
                timeOutMessage={t(adminScreenTimeOutMessage)}
            />
            <MessageBanner message={t(bannerMessage)} showValidationMessage={showValidationMessage} />
            {isShowPinView ? (
                <AdminPinView onCompletePinEntry={(pin) => validatePin(pin, dispatch)} />
            ) : (
                <AdminOptionsScreen handleAdminAction={handleAdminAction} />
            )}
            {apiCallInProgress ? <Loader showLoading={apiCallInProgress} /> : null}
            {showSomethingWentWrongScreen ? (
                <SomethingWentWrongScreen handleCloseAction={() => handleHomeClick(dispatch, navigate)} />
            ) : null}
        </AdminContainerScreenView>
    );
};

export default AdminContainerScreen;
