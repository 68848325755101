import React from "react";
import { store } from "../redux/Store";
import { MatchingComponents } from "../view/web/screens/matchingScreen/MatchingContainerScreen";
import { calculateAge, handleHomeClick, splitStringToArray } from "../helpers/CommonHelper";
import { BookedPatientResult, Patient } from "../api/apiResultModels/BookedPatientResult";
import { AppointmentUIEntity, CheckInViewModel } from "./CheckInViewModel";
import { Message } from "../view/web/screens/checkIn/CheckInSuccessScreen";
import { internetConnectivity } from "../utils/InternetConnectivity";
import { Dispatch, UnknownAction } from "redux";
import { setAPICallInProgress, updateUsageLogs } from "../redux/actions/GlobalAction";
import { LoginViewModel } from "./LoginViewModel";
import { FPatient, PatientFindResult } from "../api/apiResultModels/PatientFindResult";
import { isEqualIgnoreCase, isNonEmpty, isNotEqualIgnoreCase } from "../helpers/StringHelper";
import { AppConfig } from "../utils/AppConfig";
import { LocalStorageKey, UsageLog } from "../constants/StringConstant";
import { KioskUIEntity } from "../models/KioskUIEntity";
import { OrganizationUIEntity } from "../models/OrganizationUIEntity";
import { MessagesParameters } from "../api/apiParameterModels/MessagesParameters";
import { APIRepoKeys } from "../api/APIRepoKeys";
import { apiContainer } from "../api/APIContainer";
import { IMessagesRepository } from "../api/interfaces/IMessagesRepository";
import { ISurveyRepository } from "../api/interfaces/ISurveyRepository";
import { GetMessageResult } from "../api/apiResultModels/GetMessageResult";
import { NavigateFunction } from "react-router-dom";
import { SurveyResult } from "../api/apiResultModels/SurveyResult";
import { setNonAnonymousSurveys } from "../redux/actions/ConfigAction";
import { PostNonAnonymousSurveyParameters } from "../api/apiParameterModels/PostNonAnonymousSurveyParameters";
import { convertToISO8601 } from "../helpers/DateTimeHelper";
import { RouterName } from "../navigation/RouterName";

export const CheckInContainerViewModel = () => {

  const messageAPIRepository = apiContainer.get<IMessagesRepository>(
    APIRepoKeys.MESSAGES_API_REPOSITORY,
  );

  const surveyRepository = apiContainer.get<ISurveyRepository>(
      APIRepoKeys.SURVEY_API_REPOSITORY,
  );

  const [matchingComponents, setMatchingComponents] = React.useState<MatchingComponents>({ date: '', day: '', month: '', year: '', gender: '', surNameFirstLetter: '', postalCode: '' })
  const [showMatchingScreen, setShowMatchingScreen] = React.useState(true);
  const [showPatientInfoScreen, setShowPatientInfoScreen] = React.useState(false);
  const [showNoMatchingScreen, setShowNoMatchingScreen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState<number>(0)
  const [showAppointmentListScreen, setShowAppointmentListScreen] = React.useState<boolean>(false)
  const [showAppointmentSuccessScreen, setShowAppointmentSuccessScreen] = React.useState<boolean>(false)
  const [showSomethingWentWrongScreen, setShowSomethingWentWrongScreen] = React.useState(false)
  const patientMatchTitle = store.getState().configSlice.kioskDetails?.patientMatchTitle
  const matchTitles = splitStringToArray(',', patientMatchTitle)
  const [showPostCode, setShowPostCode] = React.useState<boolean>(false)
  const [postCodes, setPostCodes] = React.useState<string[]>([])
  const [selectedPatient, setSelectedPatient] = React.useState<Patient | undefined>(undefined)
  const [selectedAppointments, setSelectedAppointments] = React.useState<AppointmentUIEntity[]>([])
  const [appointments, setAppointments] = React.useState<AppointmentUIEntity[]>([])
  const [messages, setMessages] = React.useState<Message[]>([])
  const isOnline = internetConnectivity.checkConnectivity();
  const [timeOutMessageContent, setTimeOutMessageContent] = React.useState<string>('')

  const setTimeOutMessage = (message: string, timer?: number) => {
    setTimeOutMessageContent(message)
    if (timer) {
      setTimeout(() => {
          clearTimeOutMessage()
      }, timer);
    }
  }

  const clearTimeOutMessage = () => {
    setTimeOutMessageContent('')
  }

  function initializeStateVariables() {
    setMatchingComponents({ date: '', day: '', month: '', year: '', gender: '', surNameFirstLetter: '', postalCode: '' });
    setShowMatchingScreen(false);
    setShowNoMatchingScreen(false);
    setShowAppointmentListScreen(false);
    setShowAppointmentSuccessScreen(false);
    setShowSomethingWentWrongScreen(false)
    setShowPatientInfoScreen(false);
    setShowPostCode(false);
    setCurrentIndex(0);
  }

  function handleBackAction(onComplete: () => void) {
    if (showPostCode) {
        setShowPostCode(false);
        setShowAppointmentListScreen(false);
        setShowMatchingScreen(true);
    } else if (showPatientInfoScreen) {
        setShowPatientInfoScreen(false);
        setShowMatchingScreen(true);
    } else if (currentIndex === 0) {
        if (showAppointmentListScreen) {
            setCurrentIndex(matchTitles.length - 1);
            setShowMatchingScreen(true);
            setShowAppointmentListScreen(false);
        } else {
            onComplete();
        }
    } else if (currentIndex === matchTitles.length - 1 && showAppointmentListScreen) {
        setShowAppointmentListScreen(false);
        setShowMatchingScreen(true);
    } else {
        setCurrentIndex(preValue => preValue > 0 ? preValue - 1 : preValue);
    }
  }

  async function onCompletionCheckInPreRequisite(matchingComponents: MatchingComponents, 
    autoConfirmArrival: boolean, autoConfirmMultipleArrival: boolean, showDemographicDetails: boolean, dispatch: Dispatch<UnknownAction>): Promise<void> {
    setMatchingComponents(matchingComponents);
    initiatePatientFind(matchingComponents, autoConfirmArrival, autoConfirmMultipleArrival, showDemographicDetails, dispatch);
  }

  async function initiatePatientFind(matchingComponents: MatchingComponents, 
    autoConfirmArrival: boolean, autoConfirmMultipleArrival: boolean, showDemographicDetails: boolean, dispatch: Dispatch<UnknownAction>
  ) {
    if (!isOnline) {
        return;
    }
    try {
        dispatch(setAPICallInProgress(true));
        const isValidSession = await LoginViewModel().validateSession(dispatch)
        if (isValidSession) {
            const patientFindResult = await CheckInViewModel().initiatePatientFindAPI(matchingComponents)
            handlePatientFindResult(patientFindResult, matchingComponents, autoConfirmArrival, autoConfirmMultipleArrival, showDemographicDetails, dispatch)
        } else {
            //Logout the user
            dispatch(setAPICallInProgress(false));
        }
    }
    catch (error) {
        dispatch(setAPICallInProgress(false));
        if (error instanceof Error) {
            handleAPIError(error)
        }
    }
  }

  async function handlePatientFindResult(patientFindResult: PatientFindResult, matchingComponents: MatchingComponents,
    autoConfirmArrival: boolean, autoConfirmMultipleArrival: boolean, showDemographicDetails: boolean, dispatch: Dispatch<UnknownAction>
  ) {
    const patients = patientFindResult.patients ?? []
    let filteredPatients: FPatient[] = []
    if (patients.length > 0 && isNonEmpty(matchingComponents.gender) && (isEqualIgnoreCase(matchingComponents.gender.charAt(0), 'I') || isEqualIgnoreCase(matchingComponents.gender.charAt(0), 'U'))) {
        filteredPatients = [...patients].filter(patient => isNotEqualIgnoreCase(patient.sex, 'M') && isNotEqualIgnoreCase(patient.sex, 'F'))
    } else {
        filteredPatients = [...patients]
    }
    if (filteredPatients.length === 0 || (isNonEmpty(matchingComponents.postalCode) && filteredPatients.length > 1)) {
        handleNotYouAction(matchingComponents, dispatch);
    } else if (filteredPatients.length === 1) {
        await initiateBookedPatient(filteredPatients[0], matchingComponents, autoConfirmArrival, autoConfirmMultipleArrival, showDemographicDetails, dispatch)
    } else {
        await handleMultiplePatients(filteredPatients, matchingComponents, autoConfirmArrival, autoConfirmMultipleArrival, showDemographicDetails, dispatch)
    }
    setMatchingComponents({ ...matchingComponents, postalCode: '' })
  }

  async function initiateBookedPatient(patient: Patient, matchingComponents: MatchingComponents, 
    autoConfirmArrival: boolean, autoConfirmMultipleArrival: boolean, showDemographicDetails: boolean, dispatch: Dispatch<UnknownAction>
  ) {
      if (!isOnline) {
          dispatch(setAPICallInProgress(false))
          return;
      }
      try {
          const bookedPatientResult = await CheckInViewModel().initiateBookedPatientsAPI()
          await handleBookedPatientResult(patient, bookedPatientResult, matchingComponents, autoConfirmArrival, autoConfirmMultipleArrival, showDemographicDetails, dispatch)
      }
      catch (error) {
          dispatch(setAPICallInProgress(false));
          if (error instanceof Error) {
              handleAPIError(error)
          }
      }
  }

  async function handleBookedPatientResult(patient: Patient, bookedPatientResult: BookedPatientResult, matchingComponents: MatchingComponents,
      autoConfirmArrival: boolean, autoConfirmMultipleArrival: boolean, showDemographicDetails: boolean, dispatch: Dispatch<UnknownAction>
  ) {
      if (isNonEmpty(patient.patientId)) {
          const bookings = bookedPatientResult.bookedList?.booked?.filter(booked => booked.patient?.patientId === patient.patientId) ?? [];
          const bookedPatient = bookedPatientResult.bookedList?.booked?.find(booked => booked.patient?.patientId === patient.patientId)?.patient
          if (bookings.length > 0 && bookedPatient) {
              const divertedSessionHolderIDs = await CheckInViewModel().getDivertedSessionHolderIDs();
              setSelectedPatient(bookedPatient);
              const appointmentUIEntities: AppointmentUIEntity[] = CheckInViewModel().mapToAppointmentUIEntity(bookings, bookedPatientResult.sessionHolderList?.sessionHolder ?? [], divertedSessionHolderIDs);
              const availableAppointments = appointmentUIEntities.filter(appointment => !appointment.isEarlyAppointment && !appointment.isLateAppointment && !appointment.isDivertedSessionHolder)
              const allowAutoCheckIn = (autoConfirmArrival && appointmentUIEntities.length === 1 && availableAppointments.length === 1) || (autoConfirmMultipleArrival && appointmentUIEntities.length === availableAppointments.length)
              setAppointments(appointmentUIEntities);
              if (allowAutoCheckIn) {
                  await handleCheckInAPI(dispatch, showDemographicDetails, appointmentUIEntities, patient)
              } else {
                  dispatch(setAPICallInProgress(false));
                  setShowMatchingScreen(false);
                  setShowAppointmentListScreen(true);
              }
          } else {
              handleNotYouAction(matchingComponents, dispatch);
          }
      } else {
          handleNotYouAction(matchingComponents, dispatch);
      }
  }

  async function handleMultiplePatients(patients: Patient[], matchingComponents: MatchingComponents, autoConfirmArrival: boolean, autoConfirmMultipleArrival: boolean, showDemographicDetails: boolean, dispatch: Dispatch<UnknownAction>) {
      try {
          const bookedPatientResult = await CheckInViewModel().initiateBookedPatientsAPI()
          const patientIDsFromPatientSearch = patients.map(patient => patient.patientId).filter(patientID => patientID !== undefined)
          const uniquePatientIDsFromPatientSearch = Array.from(new Set(patientIDsFromPatientSearch))
          const patientIDsFromBookedPatientResult = bookedPatientResult.bookedList?.booked?.map(booked => booked.patient?.patientId).filter(patientID => patientID !== undefined) ?? []
          const uniquePatientIDsFromBookedPatientResult = Array.from(new Set(patientIDsFromBookedPatientResult))
          const uniquePatientIDs = uniquePatientIDsFromBookedPatientResult.filter(name => uniquePatientIDsFromPatientSearch.includes(name));
          if (uniquePatientIDs.length === 1) {
              const patient = patients.find(patient => patient.patientId === uniquePatientIDs[0])
              if (patient) {
                  await handleBookedPatientResult(patient, bookedPatientResult, matchingComponents, autoConfirmArrival, autoConfirmMultipleArrival, showDemographicDetails, dispatch)
              } else {
                  handleNotYouAction(matchingComponents, dispatch);
              }
          } else if (uniquePatientIDs.length === 0 || isNonEmpty(matchingComponents.postalCode)) {
              handleNotYouAction(matchingComponents, dispatch);
          } else {
              dispatch(setAPICallInProgress(false));
              const postCodes = CheckInViewModel().getUniqueNonEmptyPostCodesFromPatients(patients);
              const updatedPostCodes = CheckInViewModel().addRandomPostCodesIfNeeded(postCodes, AppConfig.defaultValues.MaximumPostCodes);
              setPostCodes(updatedPostCodes);
              setShowPostCode(true);
          }
      }
      catch (error) {
          if (error instanceof Error) {
              handleAPIError(error)
          }
      }
  }

  function handlePostalCodeSelection(matchingComponents: MatchingComponents, autoConfirmArrival: boolean, autoConfirmMultipleArrival: boolean, showDemographicDetails: boolean, dispatch: Dispatch<UnknownAction>) {
      setMatchingComponents(matchingComponents);
      initiatePatientFind(matchingComponents, autoConfirmArrival, autoConfirmMultipleArrival, showDemographicDetails, dispatch);
  }

  async function handleCheckInAPI(dispatch: Dispatch<UnknownAction>, showDemographicDetails: boolean, selectedAppointments: AppointmentUIEntity[], patient?: Patient): Promise<void> {
      if (!isOnline) {
          dispatch(setAPICallInProgress(false));
          return;
      }
      const selectedAppointmentIDs = selectedAppointments.map(appointment => appointment.appointmentId)
      try {
          const appointmentStatusResult = await CheckInViewModel().initiateCheckInAPI(selectedAppointmentIDs)
          let appointments: AppointmentUIEntity[] = []
          appointmentStatusResult.forEach((result, index) => {
              if (result.outcome === '1') {
                  appointments.push(selectedAppointments[index])
              }
          })
          dispatch(updateUsageLogs(`${UsageLog.CheckIn} ${selectedPatient?.patientId}`))
          initiateMessagesAPI(dispatch, showDemographicDetails, appointments, patient)
      }
      catch (error) {
          dispatch(setAPICallInProgress(false));
          if (error instanceof Error) {
              handleAPIError(error)
          }
      }
  }

  async function initiateMessagesAPI(dispatch: Dispatch<UnknownAction>, showDemographicDetails: boolean, appointmentUIEntities: AppointmentUIEntity[], patient?: Patient) {
      const _patient = patient ?? selectedPatient
      try {
          const selectedKiosk: KioskUIEntity = JSON.parse(localStorage.getItem(LocalStorageKey.SelectedKiosk) ?? "")
          const selectedOrg: OrganizationUIEntity = JSON.parse(localStorage.getItem(LocalStorageKey.SelectedOrg) ?? "")
          let params: MessagesParameters = {
              orgId: selectedOrg.id,
              kioskId: selectedKiosk.id,
          }
          if (_patient?.patientId) {
              params = { ...params, patientId: _patient.patientId }
          }
          if (_patient?.sex) {
              params = { ...params, gender: (isEqualIgnoreCase(_patient.sex, 'M') || isEqualIgnoreCase(_patient.sex, 'F')) ? _patient.sex : 'none' }
          }
          if (_patient?.dateOfBirth) {
              params = { ...params, age: `${calculateAge(_patient.dateOfBirth)}` }
          }
          const sessionHolderIDs = appointmentUIEntities.map(appointmentUIEntity => appointmentUIEntity.sessionHolderId ?? '').filter(sessionHolderId => isNonEmpty(sessionHolderId))
          if (sessionHolderIDs.length > 0) {
              params = { ...params, sessionHolderIds: sessionHolderIDs.join(',') }
          }
          
          const result = await messageAPIRepository.getMessages(params)
          const messageResult: GetMessageResult = JSON.parse(result)
          const messageUIEntities = messageResult.data?.map((message, index) => {
              if (message.attributes?.alterText && isNonEmpty(message.attributes.alterText)) {
                  const entity: Message = {
                      id: `${message.id ?? index}`,
                      text: message.attributes.alterText,
                      type: message.attributes.displayFormatTypeId ?? 0,
                  }
                  return entity
              }
              return undefined
          }).filter((message): message is Message => message !== undefined) ?? []
          setMessages(messageUIEntities)
          handleCheckInAPIResult(appointmentUIEntities, showDemographicDetails, dispatch)
      } catch (error) {
          handleCheckInAPIResult(appointmentUIEntities, showDemographicDetails, dispatch)
          if (error instanceof Error) {
              handleAPIError(error)
          }
      } finally {
          dispatch(setAPICallInProgress(false));
      }
  }

  function handleCheckInAPIResult(appointments: AppointmentUIEntity[], showDemographicDetails: boolean, dispatch: Dispatch<UnknownAction>) {
      const demographicDetailsList = store.getState().configSlice.kioskDemographicDetailsTypes ?? []
      setSelectedAppointments(appointments);
      setShowMatchingScreen(false);
      setShowAppointmentListScreen(false);
      if (showDemographicDetails && demographicDetailsList.length > 0) {
          setShowPatientInfoScreen(true);
      } else {
          setShowAppointmentSuccessScreen(true);
      }
      dispatch(setAPICallInProgress(false));
  }

  function handleNotYouAction(matchingComponents: MatchingComponents, dispatch: Dispatch<UnknownAction>): void {
      initializeStateVariables();
      setMatchingComponents(matchingComponents);
      setShowNoMatchingScreen(true);
      dispatch(setAPICallInProgress(false));
  }

  function handleAPIError(error?: Error) {
      initializeStateVariables();
      setShowSomethingWentWrongScreen(true)
  }

  function tryAgainAction() {
      initializeStateVariables();
      setShowMatchingScreen(true);
  }

  function handlePatientInfoYesAction() {
    setShowPatientInfoScreen(false)
    setShowAppointmentSuccessScreen(true)
  }

  const triggerNonAnonymousSurvey = async (dispatch: Dispatch<UnknownAction>, navigate: NavigateFunction) => {
      const currentKiosk = store.getState().globalSlice.currentKiosk
      const showQuestionnairesModule = store.getState().configSlice.modules.find(module => module.moduleId === 2) !== undefined
      if (!showQuestionnairesModule || !currentKiosk || appointments.length === 0 || !appointments[0].appointmentDate || !appointments[0].sessionHolderId || !appointments[0].sessionHolderGuid || !selectedPatient?.patientId) {
          handleHomeClick(dispatch, navigate)
          return
      }

      const kioskID = store.getState().globalSlice.currentKiosk?.id
      if (kioskID && selectedPatient?.patientId) {
          try {
              dispatch(setAPICallInProgress(true));
              const response = await surveyRepository.getNonAnonymousSurvey(kioskID, selectedPatient.patientId)
              const result: SurveyResult = JSON.parse(response)
              if (result.data && result.data.length > 0) {
                  dispatch(setNonAnonymousSurveys(result.data))
                  const params: PostNonAnonymousSurveyParameters = {
                      PatientNumber: parseInt(selectedPatient.patientId),
                      AppointmentTime: convertToISO8601(appointments[0].appointmentDate, appointments[0].appointmentTime),
                      SessionHolderId: parseInt(appointments[0].sessionHolderId),
                      SessionHolderGuid: appointments[0].sessionHolderGuid,
                      Questions: []
                  }
                  setTimeout(() => {
                      navigate(RouterName.Survey, { replace: true, state: { from: `${RouterName.CheckIn}`, params: params, nonAnonymousSurveys: result.data } });
                  }, 500);
              } else {
                  handleHomeClick(dispatch, navigate)
              }
          } catch (error) {
              console.log('error => ', error)
          } finally {
              dispatch(setAPICallInProgress(false));
          }
      }
  };

  return {
    setTimeOutMessage,
    clearTimeOutMessage,
    initializeStateVariables,
    handleBackAction,
    onCompletionCheckInPreRequisite,
    handlePostalCodeSelection,
    tryAgainAction,
    triggerNonAnonymousSurvey,
    currentIndex,
    showMatchingScreen,
    showPatientInfoScreen,
    setShowPatientInfoScreen,
    showNoMatchingScreen,
    showAppointmentListScreen,
    setShowAppointmentListScreen,
    showAppointmentSuccessScreen,
    showSomethingWentWrongScreen,
    matchingComponents,
    postCodes,
    selectedAppointments,
    messages,
    timeOutMessageContent,
    matchTitles,
    showPostCode,
    setShowPostCode,
    setCurrentIndex,
    setMatchingComponents,
    handleNotYouAction,
    appointments, 
    selectedPatient,
    handleCheckInAPI,
    handlePatientInfoYesAction,
    initiatePatientFind
  }

}