/* BookAppointmentSlotSelection.tsx */
import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Strings } from "../../../../../constants/StringConstant";
import { DateFormat, formatDate } from "../../../../../helpers/DateTimeHelper";
import { setAPICallInProgress } from "../../../../../redux/actions/GlobalAction";
import { RootState, useAppDispatch } from "../../../../../redux/Store";
import { SlotUIEntity } from "../../../../../uiEntities/SlotUIEntity";
import { BookAppointmentSlotSelectionViewModel } from "../../../../../viewModels/BookAppointmentSlotSelectionViewModel";
import { BookAppointmentViewModel } from "../../../../../viewModels/BookAppointmentViewModel";
import { BookAppointmentMainContainer, BookAppointmentSlotListView, BookAppointmentSlotSelectionContainer, BookAppointmentSlotSelectionSelectedDateText, BookAppointmentSlotsMainView, BookAppointmentTopContainer, BookAppointmentTopDivider, NoAppointmentText } from "../../../styles/StylesBookAppointment";
import DateStripComponent from "../dateStripComponent/DateStripComponent";
import SessionHolderNameListView from "./SessionHolderNameListView";
import SlotListView from "./SlotListView";

interface BookAppointmentSlotSelectionProps {
    selectedDate: Date
    handleCalendarIconSelection: () => void
    handleSlotSelection: (selectedSlot: SlotUIEntity) => void
    slotTypeId: string
}

const BookAppointmentSlotSelection: React.FC<BookAppointmentSlotSelectionProps> = (props: BookAppointmentSlotSelectionProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const formattedSelectedDate = formatDate(props.selectedDate, DateFormat.ddd_DD_MMMM_YYYY)
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const apiCallInProgress = useSelector((state: RootState) => state.globalSlice.apiCallInProgress);

    const {
        sessionHolders,
        sessionsToShow,
        selectedSessionHolder,
        updateSessionsForTheSessionHolder,
        setSlotTypeId,
        updateSessionsForSelectedDateWeek,
        weekWiseCalendarComponents,
        handleDateSelection,
        sessionsForTheSelectedDate,
        selectedDate,
        slotTypeId,
        setSelectedDate,
        showLoader,
    } = BookAppointmentSlotSelectionViewModel();

    React.useEffect(() => {
        if (props.slotTypeId !== '') {
            setSlotTypeId(props.slotTypeId)
            setSelectedDate(props.selectedDate)
        }
    }, [props.slotTypeId, props.selectedDate])

    React.useEffect(() => {
        if (slotTypeId !== "" && selectedDate !== undefined) {
            handleDateSelection(selectedDate)
        }
    }, [slotTypeId, selectedDate])

    React.useEffect(() => {
        dispatch(setAPICallInProgress(showLoader));
    }, [showLoader, dispatch])

    return (
        <BookAppointmentSlotSelectionContainer>
            <BookAppointmentTopContainer>
                {weekWiseCalendarComponents.length > 0 ?
                    <DateStripComponent
                        weekWiseSessionsByDate={weekWiseCalendarComponents}
                        selectedDate={selectedDate ?? props.selectedDate}
                        handleChangeInWeekSelection={updateSessionsForSelectedDateWeek}
                        handleDateSelection={(selectedDate) => {
                            setSelectedDate(selectedDate)
                        }}
                        handleCalendarIconSelection={props.handleCalendarIconSelection}
                    />
                    : null
                }
            </BookAppointmentTopContainer>
            {weekWiseCalendarComponents.length > 0 ? <BookAppointmentTopDivider /> : null}
            {sessionsToShow.length > 0 ?
                <BookAppointmentMainContainer isMobile={isMobile}>
                    {sessionHolders.length > 0 ?
                        <SessionHolderNameListView
                            holders={sessionHolders}
                            selectedSessionHolder={selectedSessionHolder}
                            handleSessionHolderSelection={(sessionHolder) => {
                                updateSessionsForTheSessionHolder(sessionsForTheSelectedDate, sessionHolder)
                            }}
                        />
                        : null
                    }
                    <BookAppointmentSlotsMainView>
                        <BookAppointmentSlotSelectionSelectedDateText>{formattedSelectedDate}</BookAppointmentSlotSelectionSelectedDateText>
                        <BookAppointmentSlotListView>
                            {
                                sessionsToShow.map((session) => {
                                    return (
                                        <SlotListView
                                            key={`${session.dbid}`}
                                            slots={session.slots ?? []}
                                            handleSlotSelection={(slot) => {
                                                const slotUIEntity = BookAppointmentViewModel().mapToSlotUIEntities(session, [slot])[0]
                                                props.handleSlotSelection(slotUIEntity)
                                            }}
                                            siteName={session.site?.name ?? ''}
                                        />
                                    )
                                })
                            }
                        </BookAppointmentSlotListView>
                    </BookAppointmentSlotsMainView>
                </BookAppointmentMainContainer>
                :
                <NoAppointmentText> {apiCallInProgress ? "" : t(Strings.BookAppointment.NoAppointments)} </NoAppointmentText>
            }
        </BookAppointmentSlotSelectionContainer>
    )
}

export default BookAppointmentSlotSelection