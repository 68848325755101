import React from "react";
import { AppointmentSessionsParameters } from "../api/apiParameterModels/AppointmentSessionsParameters";
import { AppointmentSession, Holder } from "../api/apiResultModels/AppointmentSessionsResult";
import { getStartAndEndDateTime } from "../helpers/DateTimeHelper";
import { store } from "../redux/Store";
import { BookAppointmentViewModel, CalendarComponent } from "./BookAppointmentViewModel";

export const BookAppointmentSlotSelectionViewModel = () => {
    const [selectedDate, setSelectedDate] =  React.useState<Date | undefined>(undefined)
    const [sessionHolders, setSessionHolders] = React.useState<Holder[]>([])
    const [sessionsToShow, setSessionsToShow] = React.useState<AppointmentSession[]>([])
    const [selectedSessionHolder, setSelectedSessionHolder] = React.useState<Holder | undefined>(undefined)
    const [slotTypeId, setSlotTypeId] = React.useState<string>('')
    const [sessionsForTheSelectedDate, setSessionsForTheSelectedDate] = React.useState<AppointmentSession[]>([])
    const [weekWiseCalendarComponents, setWeekWiseCalendarComponents] = React.useState<(CalendarComponent | undefined)[]>([])
    const [showSomethingWentWrongScreen, setShowSomethingWentWrongScreen] = React.useState(false)
    const [showLoader, setShowLoader] = React.useState(false)

    const updateHoldersListFromAppointmentSessions = (appointmentSessions: AppointmentSession[]) => {
        const availableSessions: AppointmentSession[] = BookAppointmentViewModel().filterSessionWithAvailableSlots(appointmentSessions);
        let sessionHolders = availableSessions.flatMap(session => session.holderList?.holder ?? [])
        const uniqueHolders = sessionHolders.filter((item, index, self) =>
            index === self.findIndex((t) => t.dbid === item.dbid)
        );
        sessionHolders = [...uniqueHolders]
        if (sessionHolders.length === 0) {
            setSessionHolders([])
            return []
        }
        const availableSessionHolders = store.getState().configSlice.sessionHolders ?? []
        if (availableSessionHolders.length > 0) {
            const sessionHolderIdsToFilter = availableSessionHolders.map(sessionHolder => sessionHolder.sessionHolderId);
            const filteredHolders = sessionHolders.filter(holder => sessionHolderIdsToFilter.includes(holder.dbid))
            sessionHolders = [...filteredHolders]
        }
        setSessionHolders([...sessionHolders])
        return sessionHolders
    }

    const updateSessionsForTheSessionHolder = (sessionsForTheSelectedDate: AppointmentSession[], selectedSessionHolder: Holder) => {
        const filteredSessions = sessionsForTheSelectedDate.filter(session => session.holderList?.holder?.some(holder => holder.dbid === selectedSessionHolder?.dbid))
        const sortedSessions = BookAppointmentViewModel().sortSessionOnStartDateTime(filteredSessions).filter(session => session.slots && session.slots.length > 0)
        setSessionsToShow(sortedSessions)
        setSelectedSessionHolder(selectedSessionHolder)    
    }

    async function updateSessionsForSelectedDateWeek(selectedWeekDate: Date) {
        setShowLoader(true);
        try {
            const allDatesOfWeek = BookAppointmentViewModel().getWeekDatesFromDate(selectedWeekDate)
            const params = BookAppointmentViewModel().getAppointmentSessionsParametersForWeekSelection(allDatesOfWeek, slotTypeId)
            if (params) {
                const appointmentSessions: AppointmentSession[] = await BookAppointmentViewModel().initiateAppointmentSessionAPI(params);
                const filteredAppointmentSessions = BookAppointmentViewModel().filterSessionWithAvailableSessionHolders(appointmentSessions)
                const sessionsByDate = BookAppointmentViewModel().convertSessionsByDate(filteredAppointmentSessions)
                const weekWiseCalendarComponents: (CalendarComponent | undefined)[] = sessionsByDate ? BookAppointmentViewModel().getCalendarComponent(allDatesOfWeek, sessionsByDate) : []

                setWeekWiseCalendarComponents(weekWiseCalendarComponents)
            } else {
                setWeekWiseCalendarComponents([])
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            setShowLoader(false);
        }
    }

    async function handleDateSelection(selectedDate: Date) {
        setShowLoader(true);
        const startEndTime = getStartAndEndDateTime(selectedDate)
        const params: AppointmentSessionsParameters = {
            slotType: slotTypeId,
            startDate: startEndTime.startDateTime,
            endDate: startEndTime.endDateTime,
        };
        try {
            const appointmentSessions: AppointmentSession[] = await BookAppointmentViewModel().initiateAppointmentSessionAPI(params);
            const filteredAppointmentSessions = BookAppointmentViewModel().filterSessionWithAvailableSessionHolders(appointmentSessions)
            const sessions = filteredAppointmentSessions
            if (sessions?.length > 0) {
                const sessionIDs = sessions.map(session => session?.dbid ?? -1).filter(sessionID => sessionID !== -1)
                const slotResults = await BookAppointmentViewModel().handleAllSlotsForTheSessions(sessionIDs)
                sessions.forEach((session, index) => {
                    const slots = slotResults[index].slots
                    if (slots && slots.length > 0) {
                        const futureAvailableSlots = BookAppointmentViewModel().getFutureAndAvailableSlots(slots, slotTypeId, session.date)
                        session.slots = futureAvailableSlots
                    } else {
                        session.slots = []
                    }
                });
                setSessionsForTheSelectedDate(sessions)
                const sessionHolders = updateHoldersListFromAppointmentSessions(sessions)
                if (sessionHolders.length > 0) {
                    updateSessionsForTheSessionHolder(sessions, sessionHolders[0])
                }
            } else {
                setSessionsForTheSelectedDate([])
                updateHoldersListFromAppointmentSessions([])
            }
            await updateSessionsForSelectedDateWeek(selectedDate)
        }
        catch (error) {
            setShowSomethingWentWrongScreen(true)
        }
        finally {
            setShowLoader(false);
        }
    }
    
    return {
        sessionHolders,
        setSessionHolders,
        sessionsToShow,
        selectedSessionHolder,
        setSelectedSessionHolder,
        updateHoldersListFromAppointmentSessions,
        updateSessionsForTheSessionHolder,
        slotTypeId,
        setSlotTypeId,
        updateSessionsForSelectedDateWeek,
        weekWiseCalendarComponents,
        sessionsForTheSelectedDate,
        handleDateSelection,
        showSomethingWentWrongScreen,
        selectedDate,
        setSelectedDate,
        showLoader
    };
}