import { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import { useEffect, useRef, useState } from 'react';
import { NavigateFunction } from "react-router-dom";
import { handleHomeClick } from "../helpers/CommonHelper";
import { store } from "../redux/Store";

const useIdleTimer = (timeoutDuration: number, dispatch: Dispatch<UnknownAction>, navigate: NavigateFunction, enableTimer: boolean = true) => {
  const [isIdle, setIsIdle] = useState<boolean>(false);
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const resetTimer = () => {
      setIsIdle(false);
      setTimeRemaining(null);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      startTimer();
    };

    const handleIdle = () => {
      setIsIdle(true);
      setTimeRemaining(0); // Set to 0 when timed out
    };

    const startTimer = () => {
      timeoutRef.current = setTimeout(() => {
        handleIdle();
      }, timeoutDuration);
    };

    const handleActivity = () => {
      resetTimer();
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);
    window.addEventListener('click', handleActivity);
    window.addEventListener('scroll', handleActivity);

    startTimer();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('scroll', handleActivity);
    };
  }, [timeoutDuration]);

  useEffect(() => {
    if (!enableTimer) {
      return;
    }
    if (isIdle) {
      const countdownStart = timeoutDuration - 3000; // Start countdown at 7 seconds

      intervalRef.current = setInterval(() => {
        setTimeRemaining((prev) => {
          const apiCallInProgress = store.getState().globalSlice.apiCallInProgress
          if (apiCallInProgress) {
            return prev
          }
          if (prev !== null && prev > 0) {
            return prev - 1000; // Decrease by 1 second
          }
          handleHomeClick(dispatch, navigate)
          return 0; // Set to 0 when timed out
        });
      }, 1000);

      setTimeRemaining(countdownStart); // Initialize countdown

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [dispatch, isIdle, navigate, timeoutDuration]);

  return { isIdle, timeRemaining };
};

export default useIdleTimer;
