import { injectable } from "inversify";
import { BaseURL, getAccessToken, getAdminAccessToken } from "../../utils/ApiRequestUtil";
import { AppConfig } from "../../utils/AppConfig";
import { DD_APIEndPointName } from "../../utils/DDAPIEndpointName";
import { APIClientElectron } from "../APIClientElectron";
import { withRetry } from "../APIRetryUtil";
import { IKioskRepository } from "../interfaces/IKioskRepository";


@injectable()
export class KioskRepository implements IKioskRepository {
    getKioskList(): Promise<string> {
        return new Promise((resolve, reject) => {
            const accessToken = `Bearer ${getAdminAccessToken()}`
            APIClientElectron.getRequestWithOutCertificate(
                DD_APIEndPointName.KioskList,
                `${BaseURL.emisXBaseURL}${AppConfig.emisxAPIUrlEndPoint.kiosk}`,
                accessToken)
                .then(response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    getKioskDetail(kioskId: string): Promise<string> {
        const params = { KioskId: kioskId };
        const accessToken = `Bearer ${getAdminAccessToken()}`
        return new Promise((resolve, reject) => {
            APIClientElectron.getRequestWithOutCertificate(
                DD_APIEndPointName.kioskDetail,
                `${BaseURL.emisXBaseURL}${AppConfig.emisxAPIUrlEndPoint.kioskDetail}`,
                accessToken,
                params)
                .then(response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    getKioskConfiguration(kioskId: string): Promise<string> {
        const params = { KioskId: kioskId };
        return withRetry(() => {
            return new Promise((resolve, reject) => {
                const accessToken = `Bearer ${getAccessToken()}`
                APIClientElectron.getRequestWithOutCertificate(
                    DD_APIEndPointName.kioskConfiguration,
                    `${BaseURL.emisXBaseURL}${AppConfig.emisxAPIUrlEndPoint.kioskConfiguration}`,
                    accessToken,
                    params)
                    .then(response => {
                        resolve(response);
                    })
                    .catch((error: Error) => {
                        reject(error);
                    });
            });
        }, AppConfig.retryCount.kioskConfig, AppConfig.retryDelay.kioskConfig);
    }
}