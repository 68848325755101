import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiContainer } from '../../../../api/APIContainer';
import { APIRepoKeys } from '../../../../api/APIRepoKeys';
import { KioskDetailResult, Organization } from '../../../../api/apiResultModels/KioskDetailResult';
import { KioskDetail } from '../../../../api/apiResultModels/KioskListResult';
import { IKioskRepository } from '../../../../api/interfaces/IKioskRepository';
import { ButtonText, LocalStorageKey, Strings } from "../../../../constants/StringConstant";
import { isEqualIgnoreCase, isNonEmpty } from '../../../../helpers/StringHelper';
import { KioskUIEntity } from '../../../../models/KioskUIEntity';
import { OrganizationUIEntity } from '../../../../models/OrganizationUIEntity';
import { RouterName } from '../../../../navigation/RouterName';
import { setAPICallInProgress, setCurrentKiosk } from '../../../../redux/actions/GlobalAction';
import { RootState, store, useAppDispatch } from '../../../../redux/Store';
import { AppConfig } from '../../../../utils/AppConfig';
import { DD_APIEndPointName } from '../../../../utils/DDAPIEndpointName';
import APIFailScreen from '../../commonViews/APIFailScreen';
import HeaderQuestion from '../../commonViews/HeaderQuestion';
import Loader from '../../commonViews/Loader';
import MessageBanner from "../../commonViews/MessageBanner";
import TopBar from "../../commonViews/TopBar";
import { HeaderQuestionContainer } from '../../styles/StylesMatchingScreen';
import { AlertContent } from '../home/HomeScreen';
import KioskListGridView from './KioskListGridView';
import { KioskListContainerView, KioskListView } from './StylesKiosk';

export interface OrgCredentials {
    ServiceName: string,
    UserName: string,
    Password: string,
}

const kioskRepository = apiContainer.get<IKioskRepository>(
    APIRepoKeys.KIOSK_API_REPOSITORY,
);

const KioskListScreen: React.FC = () => {
    const [showAlert, setShowAlert] = React.useState<boolean>(false)
    const [lastAPICall, setLastAPICall] = React.useState<string | undefined>(undefined)
    const [alertContent, setAlertContent] = React.useState<AlertContent | undefined>(undefined)
    const [selectedKioskEntity, setSelectedKioskEntity] = React.useState<KioskUIEntity>()
    const apiCallInProgress = useSelector((state: RootState) => state.globalSlice.apiCallInProgress);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const kioskList = store.getState().globalSlice.kiosks ?? []

    const saveSelectedKiosk = React.useCallback((selectedKiosk: KioskUIEntity) => {
        dispatch(setCurrentKiosk(selectedKiosk))
        localStorage.setItem(LocalStorageKey.SelectedKiosk, JSON.stringify(selectedKiosk));
    }, [dispatch])

    const kioskDetailAPI = React.useCallback(async (selectedKiosk: KioskUIEntity) => {
        if (selectedKiosk.id && isNonEmpty(selectedKiosk.id)) {
            setLastAPICall(DD_APIEndPointName.kioskDetail)
            dispatch(setAPICallInProgress(true));
            try {
                const responseString = await kioskRepository.getKioskDetail(selectedKiosk.id);
                const kioskDetailResult: KioskDetailResult = JSON.parse(responseString);
                const organizations = kioskDetailResult.data?.attributes?.organisations ?? []
                dispatch(setAPICallInProgress(false));
                if (organizations.length === 0) {
                    setAlertContent({ title: 'Failed', message: `No Organizations are mapped to the KIOSK\n"${selectedKiosk.name}"` })
                    setShowAlert(true)
                    return
                }
                saveOrgDetails(organizations)
                const saveCredentialsResults = await saveCredentials(organizations)
                console.log('saveCredentialsResults => ', saveCredentialsResults)
                dispatch(setAPICallInProgress(false));
                saveSelectedKiosk(selectedKiosk)
                setTimeout(() => {
                    navigate(RouterName.OrgList)
                }, 500);
            }
            catch (error) {
                console.log('error => ', error)
                dispatch(setAPICallInProgress(false));
                setAlertContent({ title: 'Failed', message: `Failed to get details for the kiosk "${selectedKiosk.name}"` })
                setShowAlert(true)
            }
        }
    }, [dispatch, navigate, saveSelectedKiosk])

    const handleKioskSelection = React.useCallback(async (selectedKiosk: KioskDetail) => {
        const selectedKioskEntity: KioskUIEntity = {
            id: selectedKiosk.id ?? '',
            name: selectedKiosk.attributes?.kioskName ?? '',
            title: selectedKiosk.attributes?.title ?? '',
            ipAddress: '',
            version: '',
            guid: selectedKiosk.attributes?.kioskGuid ?? ''
        }
        setSelectedKioskEntity(selectedKioskEntity)
        await kioskDetailAPI(selectedKioskEntity)
    }, [kioskDetailAPI]);

    function saveOrgDetails(orgs: Organization[]) {
        const orgUIEntities: OrganizationUIEntity[] = []
        for (const org of orgs) {
            let organizationUIEntity: OrganizationUIEntity = {
                id: `${org.organisationId ?? ''}`,
                name: `${org.organisationName ?? ''}`,
                guid: `${org.organisationGuid ?? ''}`,
            }
            if (org.clientSecret) {
                organizationUIEntity = { ...organizationUIEntity, secretStartDate: org.clientSecret.startDate, secretEndDate: org.clientSecret?.endDate }
            }
            orgUIEntities.push(organizationUIEntity)
        }
        localStorage.setItem(LocalStorageKey.AllOrgs, JSON.stringify(orgUIEntities));
    }

    async function saveCredentials(orgs: Organization[]): Promise<boolean[]> {
        let promises: Promise<boolean>[] = []
        for (const org of orgs) {
            if (isNonEmpty(org.clientId) && isNonEmpty(org.clientSecret?.secret)) {
                const orgClientDetails: OrgCredentials = {
                    ServiceName: AppConfig.ServiceName,
                    UserName: `ClientId.${org.organisationId}`,
                    Password: org.clientId ?? ''
                }
                const orgSecretDetails: OrgCredentials = {
                    ServiceName: AppConfig.ServiceName,
                    UserName: `ClientSecret.${org.organisationId}`,
                    Password: org.clientSecret?.secret ?? ''
                }

                promises.push(window.electron.saveOrgCredentials(JSON.stringify(orgClientDetails)))
                promises.push(window.electron.saveOrgCredentials(JSON.stringify(orgSecretDetails)))
            }
        }
        try {
            const allResult = await Promise.all(promises)
            return Promise.resolve(allResult)
        }
        catch (error) {
            console.log('error while saving the credentials => ', error)
            if (error instanceof Error) {
                return Promise.reject(error);
            } else {
                return Promise.reject(new Error('An unknown error occurred'));
            }
        }
    }

    function handleRetryAction() {
        setAlertContent(undefined)
        setShowAlert(false)
        if (isEqualIgnoreCase(lastAPICall, DD_APIEndPointName.kioskDetail) && selectedKioskEntity) {
            kioskDetailAPI(selectedKioskEntity)
        }
    }

    return (
        <KioskListContainerView>
            <TopBar
                isShowHomeButton={false}
                isShowBackOption={false}
                isShowLanguageOption={false}
                handleBackClick={() => { }}
                timeOutMessage={''}
            />
            {!showAlert ?
                <KioskListView>
                    {kioskList.length > 0 ? <MessageBanner message={Strings.Home.Welcome} /> : null}
                    <HeaderQuestionContainer>
                        <HeaderQuestion headerText={kioskList && kioskList.length > 0 ? Strings.SelectKIOSK : ''} subHeaderText={''} />
                    </HeaderQuestionContainer>
                    <KioskListGridView kioskList={kioskList} handleKioskSelection={handleKioskSelection} />
                </KioskListView>
                : null
            }
            {showAlert && alertContent ?
                <APIFailScreen
                    title={alertContent?.title ?? ''}
                    information={alertContent?.message ?? ""}
                    tryAgainAction={handleRetryAction}
                    buttonText={ButtonText.TryAgain}
                />
                : null
            }
            {apiCallInProgress ? <Loader showLoading={apiCallInProgress} /> : null}
        </KioskListContainerView>
    )
}

export default KioskListScreen
