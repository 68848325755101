import * as ERROR_MSGS from '../constants/error_msgs';
import { BindingScopeEnum, BindingTypeEnum } from '../constants/literal_types';
import { BindingInWhenOnSyntax } from './binding_in_when_on_syntax';
import { BindingWhenOnSyntax } from './binding_when_on_syntax';
var BindingToSyntax = (function () {
    function BindingToSyntax(binding) {
        this._binding = binding;
    }
    BindingToSyntax.prototype.to = function (constructor) {
        this._binding.type = BindingTypeEnum.Instance;
        this._binding.implementationType = constructor;
        return new BindingInWhenOnSyntax(this._binding);
    };
    BindingToSyntax.prototype.toSelf = function () {
        if (typeof this._binding.serviceIdentifier !== 'function') {
            throw new Error(ERROR_MSGS.INVALID_TO_SELF_VALUE);
        }
        var self = this._binding
            .serviceIdentifier;
        return this.to(self);
    };
    BindingToSyntax.prototype.toConstantValue = function (value) {
        this._binding.type = BindingTypeEnum.ConstantValue;
        this._binding.cache = value;
        this._binding.dynamicValue = null;
        this._binding.implementationType = null;
        this._binding.scope = BindingScopeEnum.Singleton;
        return new BindingWhenOnSyntax(this._binding);
    };
    BindingToSyntax.prototype.toDynamicValue = function (func) {
        this._binding.type = BindingTypeEnum.DynamicValue;
        this._binding.cache = null;
        this._binding.dynamicValue = func;
        this._binding.implementationType = null;
        return new BindingInWhenOnSyntax(this._binding);
    };
    BindingToSyntax.prototype.toConstructor = function (constructor) {
        this._binding.type = BindingTypeEnum.Constructor;
        this._binding.implementationType = constructor;
        this._binding.scope = BindingScopeEnum.Singleton;
        return new BindingWhenOnSyntax(this._binding);
    };
    BindingToSyntax.prototype.toFactory = function (factory) {
        this._binding.type = BindingTypeEnum.Factory;
        this._binding.factory = factory;
        this._binding.scope = BindingScopeEnum.Singleton;
        return new BindingWhenOnSyntax(this._binding);
    };
    BindingToSyntax.prototype.toFunction = function (func) {
        if (typeof func !== 'function') {
            throw new Error(ERROR_MSGS.INVALID_FUNCTION_BINDING);
        }
        var bindingWhenOnSyntax = this.toConstantValue(func);
        this._binding.type = BindingTypeEnum.Function;
        this._binding.scope = BindingScopeEnum.Singleton;
        return bindingWhenOnSyntax;
    };
    BindingToSyntax.prototype.toAutoFactory = function (serviceIdentifier) {
        this._binding.type = BindingTypeEnum.Factory;
        this._binding.factory = function (context) {
            var autofactory = function () {
                return context.container.get(serviceIdentifier);
            };
            return autofactory;
        };
        this._binding.scope = BindingScopeEnum.Singleton;
        return new BindingWhenOnSyntax(this._binding);
    };
    BindingToSyntax.prototype.toAutoNamedFactory = function (serviceIdentifier) {
        this._binding.type = BindingTypeEnum.Factory;
        this._binding.factory = function (context) {
            return function (named) {
                return context.container.getNamed(serviceIdentifier, named);
            };
        };
        return new BindingWhenOnSyntax(this._binding);
    };
    BindingToSyntax.prototype.toProvider = function (provider) {
        this._binding.type = BindingTypeEnum.Provider;
        this._binding.provider = provider;
        this._binding.scope = BindingScopeEnum.Singleton;
        return new BindingWhenOnSyntax(this._binding);
    };
    BindingToSyntax.prototype.toService = function (service) {
        this.toDynamicValue(function (context) {
            return context.container.get(service);
        });
    };
    return BindingToSyntax;
}());
export { BindingToSyntax };
