import React from "react"
import { Dispatch, UnknownAction } from "redux"
import { apiContainer } from "../api/APIContainer"
import { PostAnonymousSurveyParameters, SurveyAnswer } from "../api/apiParameterModels/PostAnonymousSurveyParameters"
import { NonAnonymousSurveyQuestion, PostNonAnonymousSurveyParameters } from "../api/apiParameterModels/PostNonAnonymousSurveyParameters"
import { APIRepoKeys } from "../api/APIRepoKeys"
import { Survey } from "../api/apiResultModels/SurveyResult"
import { ISurveyRepository } from "../api/interfaces/ISurveyRepository"
import { Strings, UsageLog } from "../constants/StringConstant"
import { APIDataType } from "../enum"
import { setAnonymousSurveys, setNonAnonymousSurveys } from "../redux/actions/ConfigAction"
import { setAPICallInProgress, updateUsageLogs } from "../redux/actions/GlobalAction"
import { store } from "../redux/Store"
import { LoginViewModel } from "./LoginViewModel"
import { SurveyViewModel } from "./SurveyViewModel"

export const SurveyContainerViewModel = () => {

  const surveyRepository = apiContainer.get<ISurveyRepository>(
    APIRepoKeys.SURVEY_API_REPOSITORY,
  );

  const [surveys, setSurveys] = React.useState<Survey[]>([])
  const [showSurveyList, setShowSurveyList] = React.useState<boolean>(true)
  const [showQuestionnaireScreen, setShowQuestionnaireScreen] = React.useState<boolean>(false)
  const [showSomethingWentWrongScreen, setShowSomethingWentWrongScreen] = React.useState(false)
  const [currentIndex, setCurrentIndex] = React.useState<number>(0)
  const [showQuestionnaireSuccessScreen, setShowQuestionnaireSuccessScreen] = React.useState<boolean>(false)
  const [selectedSurvey, setSelectedSurvey] = React.useState<Survey | undefined>(undefined)
  const [surveyType, setSurveyType] = React.useState<APIDataType>(APIDataType.NonAnonymousSurvey)
  const [postNonAnonymousSurveyParameters, setPostNonAnonymousSurveyParameters] = React.useState<PostNonAnonymousSurveyParameters | undefined>(undefined)
  const kioskID = store.getState().globalSlice.currentKiosk?.id

  function resetState() {
    setShowSurveyList(false)
    setShowQuestionnaireScreen(false)
    setShowSomethingWentWrongScreen(false)
    setCurrentIndex(0)
    setShowQuestionnaireSuccessScreen(false)
    setSelectedSurvey(undefined)
  }

  function updateAnonymousSurvey() {
    setSurveyType(APIDataType.AnonymousSurvey)
    const anonymousSurveys = store.getState().configSlice.anonymousSurveys ?? []
    setSurveys(anonymousSurveys)
  }

  function updateNonAnonymousSurvey(nonAnonymousSurveys: Survey[], postNonAnonymousSurveyParameters: PostNonAnonymousSurveyParameters) {
    setPostNonAnonymousSurveyParameters(postNonAnonymousSurveyParameters)
    setSurveyType(APIDataType.NonAnonymousSurvey)
    setSurveys(nonAnonymousSurveys)
  }

  function resetSurvey(dispatch: Dispatch<UnknownAction>) {
    dispatch(setAnonymousSurveys([]))
    dispatch(setNonAnonymousSurveys([]))
  }

  const handleBackAction = (onComplete: () => void) => {
    if (showSurveyList) {
      onComplete();
    } else if (showQuestionnaireScreen) {
      if (currentIndex === 0) {
        setShowQuestionnaireScreen(false)
        setShowSurveyList(true)
      } else {
        setCurrentIndex(currentIndex - 1)
      }
    }
  }

  const surveyTapAction = (survey: Survey) => {
    if (survey.attributes?.questions && survey.attributes.questions.length > 0) {
      setSelectedSurvey(survey)
      setShowQuestionnaireScreen(true)
      setShowSurveyList(false)
    }
  }

  const handleQuestionnairesCompletion = async (surveyAnswers: SurveyAnswer[], dispatch: Dispatch<UnknownAction>, onComplete: () => void) => {
    if (surveyAnswers.length === 0) {
      return
    }
    try {
      dispatch(setAPICallInProgress(true));
      const isValidSession = await LoginViewModel().validateSession(dispatch)
      if (isValidSession) {
        if (surveyType === APIDataType.NonAnonymousSurvey && postNonAnonymousSurveyParameters) {
          const nonAnonymousSurveyQuestion: NonAnonymousSurveyQuestion[] = SurveyViewModel().mapToNonAnonymousSurveyParameters(surveyAnswers)
          const nonSkippedQuestions = nonAnonymousSurveyQuestion.filter(question => question.SelectedAnswer !== Strings.Questionnaire.Skipped)
          if (nonSkippedQuestions.length > 0) {
            const updatedParams: PostNonAnonymousSurveyParameters = { ...postNonAnonymousSurveyParameters, Questions: nonAnonymousSurveyQuestion }
            await surveyRepository.postNonAnonymousSurvey(updatedParams)
            dispatch(updateUsageLogs(`${UsageLog.Survey} ${postNonAnonymousSurveyParameters.PatientNumber}`))
            const postNonAnonymousSurveyFrequencyParameters = SurveyViewModel().mapToNonAnonymousSurveyFrequencyParameters(surveyAnswers, postNonAnonymousSurveyParameters.PatientNumber, kioskID)
            if (postNonAnonymousSurveyFrequencyParameters) {
              await surveyRepository.postNonAnonymousSurveyFrequency(postNonAnonymousSurveyFrequencyParameters)
            }
          } else {
            onComplete();
          }
        } else if (surveyType === APIDataType.AnonymousSurvey) {
          const params: PostAnonymousSurveyParameters = { data: surveyAnswers }
          await surveyRepository.postAnonymousSurvey(params)
        }
        setShowQuestionnaireScreen(false)
        setShowQuestionnaireSuccessScreen(true)
        setCurrentIndex(0)
      }
    } catch (error) {
      resetState()
      setShowSomethingWentWrongScreen(true)
    } finally {
      dispatch(setAPICallInProgress(false));
    }
  }

  return {
    surveys,
    showSurveyList,
    setShowSurveyList,
    showQuestionnaireScreen,
    setShowQuestionnaireScreen,
    showSomethingWentWrongScreen,
    setShowSomethingWentWrongScreen,
    currentIndex,
    setCurrentIndex,
    showQuestionnaireSuccessScreen,
    setShowQuestionnaireSuccessScreen,
    selectedSurvey,
    setSelectedSurvey,
    surveyType,
    setSurveyType,
    postNonAnonymousSurveyParameters,
    setPostNonAnonymousSurveyParameters,
    resetState,
    updateAnonymousSurvey,
    updateNonAnonymousSurvey,
    resetSurvey,
    handleBackAction,
    surveyTapAction,
    handleQuestionnairesCompletion
  }

}