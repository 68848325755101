import { useTranslation } from "react-i18next";
import { Strings, ButtonText } from "../../../../../../constants/StringConstant";
import { PatientInfoFooterView, PatientInfoFooterViewTitleText, PatientInfoFooterViewButtonView, PatientInfoYesButton, PatientInfoNoButton } from "../../../../styles/StylesCheckIn";

const PatientInfoScreenFooterView: React.FC<{ handleYesAction: () => void, handleNoAction: () => void }> = ({ handleYesAction, handleNoAction }) => {
    const { t } = useTranslation();
    return (
        <PatientInfoFooterView data-testid="PatientInfoFooterView">
            <PatientInfoFooterViewTitleText>
                {t(Strings.CheckIn.PatientInfo.AreTheseDetailsUpToDate)}
            </PatientInfoFooterViewTitleText>
            <PatientInfoFooterViewButtonView className="patientInfo-footerView-buttonView">
                <PatientInfoYesButton onClick={handleYesAction}>
                    {t(ButtonText.Yes)}
                </PatientInfoYesButton>
                <PatientInfoNoButton onClick={handleNoAction}>
                    {t(ButtonText.No)}
                </PatientInfoNoButton>
            </PatientInfoFooterViewButtonView>
        </PatientInfoFooterView>
    )
}

export default PatientInfoScreenFooterView