/* BookAppointmentTypeSelection.tsx */

import { Grid } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Strings } from '../../../../../constants/StringConstant'
import { getSelectedOrg } from '../../../../../helpers/CommonHelper'
import { isNonEmpty } from '../../../../../helpers/StringHelper'
import { store } from '../../../../../redux/Store'
import HeaderQuestion from '../../../commonViews/HeaderQuestion'
import { BookAppointmentTypeSelectionContainer, BookAppointmentTypeSelectionDividerView, BookAppointmentTypeSelectionHeaderQuestionView, LargeGridButton } from '../../../styles/StylesBookAppointment'
import BookAppointmentTypeSelectionHeaderView from './BookAppointmentTypeSelectionHeaderView'

interface BookAppointmentTypeSelectionProps {
    patientName: string,
    handleNotYouAction: () => void
    handleSlotTypeAction: (slotTypeId: string) => void
}

const BookAppointmentTypeSelection: React.FC<BookAppointmentTypeSelectionProps> = (props: BookAppointmentTypeSelectionProps) => {
    const { t } = useTranslation();
    const selectedOrg = getSelectedOrg()
    const slotTypes = store.getState().configSlice.slotTypes.filter(slotType => isNonEmpty(slotType.Description) && slotType.organisationId === selectedOrg?.id)

    return (
        <BookAppointmentTypeSelectionContainer>
            <BookAppointmentTypeSelectionHeaderView patientName={props.patientName} handleNotYouAction={props.handleNotYouAction} />
            <BookAppointmentTypeSelectionDividerView />
            <BookAppointmentTypeSelectionHeaderQuestionView>
                <HeaderQuestion headerText={t(Strings.BookAppointment.SelectAppointmentType)} subHeaderText={''} />
            </BookAppointmentTypeSelectionHeaderQuestionView>
            <Grid container justifyContent={'center'} style={{ overflow: 'auto' }}>
                {slotTypes.map((slotType, index) => {
                    return (
                        <Grid item key={`${index}.${slotType.SlotTypeId}`}>
                            <LargeGridButton
                                onClick={() => {
                                    props.handleSlotTypeAction(`${slotType.SlotTypeId}`)
                                }}
                                variant="contained"
                                aria-label={slotType.Description}
                            >
                                {slotType.Description}
                            </LargeGridButton>
                        </Grid>
                    )
                })}
            </Grid>
        </BookAppointmentTypeSelectionContainer>
    )

}

export default BookAppointmentTypeSelection