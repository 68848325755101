import { store } from "../redux/Store";

export const getAccessToken = () => {
    return store.getState().tokenSlice.accessToken;
};

export const getAdminAccessToken = () => {
  return store.getState().tokenSlice.adminAccessToken;
};

export const BaseURL = {
  openAPI: process.env.REACT_APP_BASE_URL_OPEN_API as string,
  token: process.env.REACT_APP_BASE_URL_CLIENT_TOKEN as string,
  certificate: process.env.REACT_APP_BASE_URL_CERTIFICATE as string,
  emisXBaseURL: process.env.REACT_APP_BASE_URL_EMISX as string,
} as const