export enum DD_APIEndPointName {
    Token = 'Get Client Token',
    PatientSearch = 'Patient Search',
    BookedPatient = 'Get Booked Patient',
    SetAppointmentStatus = 'Set Appointment Status',
    GetAppointmentSessions = 'Get Appointment Sessions',
    GetSlotsForSession = 'Get Session Slots',
    BookAppointment = 'BookAppointment',
    KioskList = 'GetKioskList',
    Members = 'GetMembersList',
    UsageLog = 'PostUsageLog',
    MessageList = 'GetMessageList',
    kioskConfiguration = 'GetKioskConfiguration',
    kioskDetail = 'GetKioskDetail',
    DeviceEnrolment = 'Get device certificate',
    Questionnaire = 'Questionnaire',
    GetAnonymousSurvey = 'Get AnonymousSurvey',
    PostAnonymousSurvey = 'Post AnonymousSurvey',
    GetNonAnonymousSurvey = 'Get NonAnonymousSurvey',
    PostNonAnonymousSurvey = 'Post NonAnonymousSurvey',
    PostNonAnonymousSurveyFrequency = 'Post NonAnonymousSurvey Frequency',
    ValidateAdminPin = 'Validate AdminPin',
}