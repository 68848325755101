import { AuthenticationResult } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useLDClient } from 'launchdarkly-react-client-sdk';
import platform from "platform";
import React from 'react';
import { getAdminTokenResult } from "./api/AccessTokenMapper";
import { TokenResult } from "./api/apiResultModels/TokenResult";
import './App.css';
import { loginRequest } from "./AuthConfig";
import { ErrorMessage, Strings } from './constants/StringConstant';
import { isKioskSelected } from "./helpers/CommonHelper";
import DateTimeHelper from './helpers/DateTimeHelper';
import { AnalyticsManager } from "./manager/AnalyticsManager";
import { LDConstants } from "./manager/LaunchDarklyManager";
import RouterComponent from './navigation/RouterComponent';
import { setCurrentDate, setCurrentTime } from './redux/actions/GlobalAction';
import { setAdminTokenDetails } from "./redux/actions/TokenAction";
import { useAppDispatch } from './redux/Store';
import CustomAlert from "./view/web/commonViews/CustomAlert";
import LanguagePopupScreen from './view/web/commonViews/LanguagePopupScreen';

const App: React.FC = () => {
  const ldClient = useLDClient();
  const dispatch = useAppDispatch();
  const dateTimeHelper = new DateTimeHelper();
  const { instance } = useMsal();
  const [authComplete, setAuthComplete] = React.useState(false);
  const [orgNumber, setOrgNumber] = React.useState<string | null>(null);
  const [showDisabledAlert, setShowDisabledAlert] = React.useState<boolean>(false);

  /* DD Initialize */
  React.useEffect(() => {
    AnalyticsManager.initializeDataDog();
  }, []);

  /* B2C Login */
  React.useEffect(() => {
    if (isKioskSelected()) {
      setAuthComplete(true)
      return
    }
    window.electron.adminAction(Strings.Admin.showMenuBar)
    const initB2CLogin = async () => {
      try {
        // Handle redirect response
        const response = await instance.handleRedirectPromise();
        if (response) {
          const authResponse: AuthenticationResult = response
          const token = await getAdminTokenResult(authResponse.accessToken);
          setOrgNumber(token.cdb ?? null);
          const tokenResult: TokenResult = {
            access_token: authResponse.accessToken,
            adminTokenExpiresOn: authResponse.expiresOn
          }
          dispatch(setAdminTokenDetails(tokenResult));
          window.electron.adminAction(Strings.Admin.hideMenuBar)
          setAuthComplete(true);
        } else {
          window.electron.adminAction(Strings.Admin.showOnScreenKeyboard)
          instance.loginRedirect(loginRequest)
            .catch(error => {
              console.error("Login failed", error);
            });
        }
      } catch (error) {
        console.error("Error during redirect handling:", error);
      }
    }
    setTimeout(() => {
      initB2CLogin()
    }, 1000);
  }, [instance, dispatch]);

  /* Update LaunchDarkly Context */
  React.useEffect(() => {
    if (ldClient && orgNumber) {
      ldClient.identify({
        kind: "org",
        key: orgNumber,
      })
        .then(() => {
          console.log("Context updated successfully")
          const isEnabled: boolean = ldClient.variation(LDConstants.Kiosk_CRO);
          setShowDisabledAlert(!isEnabled);
        })
        .catch((err) => {
          console.log(`Error updating context: ${err}`)
        });
    }
  }, [ldClient, orgNumber]);

  React.useEffect(() => {
    if (ldClient) {
      const onWindows7FlagChange = (newValue: boolean) => {
        if (newValue && platform.os?.family === 'Windows' && platform.os?.version === '7') {
          setShowDisabledAlert(true);
        }
      };
      const onKioskCROFlagChange = (newValue: boolean) => {
        setShowDisabledAlert(!newValue);
      };
      ldClient.on(`change:${LDConstants.Windows7_KillSwitch}`, onWindows7FlagChange);
      ldClient.on(`change:${LDConstants.Kiosk_CRO}`, onKioskCROFlagChange);
      return () => {
        ldClient.off(`change:${LDConstants.Windows7_KillSwitch}`, onWindows7FlagChange);
        ldClient.off(`change:${LDConstants.Kiosk_CRO}`, onKioskCROFlagChange);
      };
    }
  }, [ldClient]);

  const closeDisabledAlert = () => {
    setShowDisabledAlert(false);
    localStorage.clear()
    window.electron.relaunchApp()
  }

  /* TopBar Timer */
  React.useEffect(() => {
    const setDate = () => {
      const currentDateTime = new Date()
      const currentDate = dateTimeHelper.formatDate(currentDateTime, { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' });
      const currentTime = dateTimeHelper.formatTime(currentDateTime, { hour: '2-digit', minute: '2-digit', hour12: false }).toUpperCase();
      dispatch(setCurrentDate(currentDate))
      dispatch(setCurrentTime(currentTime))
    }
    setDate()
    const timerID = setInterval(() => {
      setDate()
    }, 1000);
    return () => {
      clearInterval(timerID);
    };
  });

  return (
    <div className="App">
      {authComplete && !showDisabledAlert ? <RouterComponent /> : <></>}
      <LanguagePopupScreen />
      {showDisabledAlert && (
        <CustomAlert
          open={showDisabledAlert}
          message={ErrorMessage.KioskIsDisabledForThisOrgOrDevice}
          title="Alert"
          onClose={() => closeDisabledAlert()}
        />
      )}
    </div>
  );
}

export default App;
