import React from "react"
import { SurveyAnswer } from "../../../../api/apiParameterModels/PostAnonymousSurveyParameters"
import { QuestionAnswerOption, Survey, SurveyQuestion } from "../../../../api/apiResultModels/SurveyResult"
import { APIDataType } from "../../../../enum"
import { store } from "../../../../redux/Store"
import { AppConfig } from "../../../../utils/AppConfig"
import { SurveyViewModel } from "../../../../viewModels/SurveyViewModel"
import { HeaderQuestionHeaderText } from "../../styles/StylesCommonView"
import { QuestionnaireScreenContainerView, QuestionnaireScreenTopView } from "../../styles/StylesQuestionnaire"
import QuestionnaireOptionsScreen from "./QuestionnaireOptionsScreen"
import QuestionnaireTextScreen from "./QuestionnaireTextScreen"

export interface QuestionnaireScreenProps {
    survey: Survey
    surveyType: APIDataType
    currentIndex: number
    setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
    onCompletionQuestionnaires: (surveyAnswers: SurveyAnswer[]) => void
}

const QuestionnaireScreen: React.FC<QuestionnaireScreenProps> = (props: QuestionnaireScreenProps) => {
    const [surveyAnswers, setSurveyAnswers] = React.useState<SurveyAnswer[]>([])
    const [surveyAnswerText, setSurveyAnswerText] = React.useState<string>('')
    const [question, setQuestion] = React.useState<SurveyQuestion>()

    React.useEffect(() => {
        const questions = props.survey.attributes?.questions ?? []
        const question = questions[props.currentIndex]
        const surveyAnswer = SurveyViewModel().getAnswerText(question, surveyAnswers)
        setSurveyAnswerText(surveyAnswer)
        setQuestion(question)
    }, [props.survey.attributes?.questions, props.currentIndex, surveyAnswers]);

    function answerAction(text: string, option?: QuestionAnswerOption, skipped: boolean = false) {
        setSurveyAnswerText(text)
        const questions = props.survey.attributes?.questions ?? []
        const currentQuestion = questions[props.currentIndex]
        if (props.survey) {
            const surveyAnswer: SurveyAnswer = {
                type: props.surveyType,
                attributes: {
                    kioskGuid: store.getState().globalSlice.currentKiosk?.guid ?? '',
                    refQuestionnaireId: props.survey.id ? parseInt(props.survey.id) : 0,
                    questionnaireTitle: props.survey.attributes?.title ?? '',
                    refQuestionId: currentQuestion.questionId,
                    questionText: currentQuestion.questionText ?? '',
                    refOptionId: SurveyViewModel().getUpdatedRefOptionId(option, skipped),
                    answerText: SurveyViewModel().getUpdatedAnswerText(text, option, skipped),
                    snomedCode: skipped ? undefined : option?.snomedCode,
                    optionCode: skipped ? undefined : option?.optionCode,
                }
            }
            const updatedAnswers = SurveyViewModel().upsertAnswer(surveyAnswer, surveyAnswers)
            if(option?.nestedQuestionId === -1 || props.currentIndex === (props.survey.attributes?.questions ?? []).length - 1) {
                props.onCompletionQuestionnaires(updatedAnswers)
            } else {
                setSurveyAnswers(updatedAnswers)
                props.setCurrentIndex(props.currentIndex + 1)
            }
        }
    }
    return (
        <QuestionnaireScreenContainerView>
            <QuestionnaireScreenTopView sx={{ textAlign: question?.questionType === 4 ? 'center' : 'left' }}>
                <HeaderQuestionHeaderText>{question?.questionText}</HeaderQuestionHeaderText>
            </QuestionnaireScreenTopView>
            {question?.questionType === 4 && (question.questionAnswerOptions?.length ?? 0) > 0 ?
                <QuestionnaireOptionsScreen
                    selectedOption={SurveyViewModel().getSelectedOptionId(question, surveyAnswers)}
                    options={question.questionAnswerOptions ?? []}
                    tapAction={(option) => answerAction('', option)}
                    skipQuestionAction={() => answerAction('', undefined, true)}
                />
                : null
            }
            {question?.questionType === 1 || question?.questionType === 2 ?
                <QuestionnaireTextScreen
                    initialValue={surveyAnswerText}
                    continueAction={(text) => answerAction(text)}
                    keyboardType={question.questionType === 1 ? 'text' : 'numeric'}
                    charLimit={question.optionCharLimit ?? AppConfig.defaultValues.MaxAllowedCharacter}
                    skipQuestionAction={() => answerAction('', undefined, true)}
                />
                : null
            }
        </QuestionnaireScreenContainerView>
    )
}

export default QuestionnaireScreen