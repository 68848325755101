import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { SurveyAnswer } from "../../../../api/apiParameterModels/PostAnonymousSurveyParameters"
import { Survey } from "../../../../api/apiResultModels/SurveyResult"
import { Strings } from "../../../../constants/StringConstant"
import { handleHomeClick } from "../../../../helpers/CommonHelper"
import { isEqualIgnoreCase } from "../../../../helpers/StringHelper"
import { RouterName } from "../../../../navigation/RouterName"
import { setAnonymousSurveys, setNonAnonymousSurveys } from "../../../../redux/actions/ConfigAction"
import { RootState, useAppDispatch } from "../../../../redux/Store"
import { SurveyContainerViewModel } from "../../../../viewModels/SurveyContainerViewModel"
import Loader from "../../commonViews/Loader"
import MessageBanner from "../../commonViews/MessageBanner"
import SomethingWentWrongScreen from "../../commonViews/SomethingWentWrongScreen"
import TopBar from "../../commonViews/TopBar"
import { SurveyContainerTopView, SurveyContainerView } from "../../styles/StylesQuestionnaire"
import QuestionnaireScreen from "./QuestionnaireScreen"
import SurveyListScreen from "./SurveyListScreen"
import SurveySuccessScreen from "./SurveySuccessScreen"

const SurveyContainer: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const apiCallInProgress = useSelector((state: RootState) => state.globalSlice.apiCallInProgress);
    const location = useLocation();

    const {
        surveys,
        showSurveyList,
        selectedSurvey,
        surveyType,
        currentIndex,
        setCurrentIndex,
        showQuestionnaireScreen,
        showSomethingWentWrongScreen,
        showQuestionnaireSuccessScreen,
        updateAnonymousSurvey,
        updateNonAnonymousSurvey,
        handleBackAction,
        surveyTapAction,
        handleQuestionnairesCompletion
    } = SurveyContainerViewModel();

    React.useEffect(() => {
        if (location.state?.from && isEqualIgnoreCase(location.state.from, RouterName.Home)) {
            updateAnonymousSurvey();
        } else if (location.state?.from && isEqualIgnoreCase(location.state.from, RouterName.CheckIn)) {
            const nonAnonymousSurveys: Survey[] = location.state?.nonAnonymousSurveys ?? []
            if (location.state.params && nonAnonymousSurveys.length > 0) {
                updateNonAnonymousSurvey(nonAnonymousSurveys, location.state.params)
            } else {
                handleHomeClick(dispatch, navigate)
            }
        }
        return () => {
            dispatch(setAnonymousSurveys([]))
            dispatch(setNonAnonymousSurveys([]))
        };
    }, [location, dispatch, navigate]);

    const onBackClick = () => {
        handleBackAction(() => navigate(RouterName.Home));
    }

    const handleCloseButtonInSuccessScreen = () => {
        handleHomeClick(dispatch, navigate)
    }

    const onCompletionQuestionnaires = async (surveyAnswers: SurveyAnswer[]) => {
        handleQuestionnairesCompletion(surveyAnswers, dispatch, onBackClick)
    }

    return (
        <SurveyContainerView>
            <SurveyContainerTopView>
                <TopBar
                    isShowHomeButton={true}
                    isShowBackOption={!showQuestionnaireSuccessScreen && !showSomethingWentWrongScreen}
                    handleBackClick={onBackClick}
                    timeOutMessage={""}
                />
                {!showSurveyList && !showQuestionnaireSuccessScreen ?
                    <MessageBanner message={t(Strings.Questionnaire.TheSurgeryWouldLikeToAsk)} />
                    : null
                }
            </SurveyContainerTopView>
            {showSurveyList && surveys.length > 0 ?
                <SurveyListScreen
                    surveys={surveys}
                    surveyTapAction={surveyTapAction}
                />
                : null
            }
            {showQuestionnaireScreen && selectedSurvey ?
                <QuestionnaireScreen
                    surveyType={surveyType}
                    survey={selectedSurvey}
                    onCompletionQuestionnaires={onCompletionQuestionnaires}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                />
                : null
            }                                                                                                                                                                                                                                                                                                                                                          
            {showQuestionnaireSuccessScreen ?
                <SurveySuccessScreen handleCloseAction={handleCloseButtonInSuccessScreen} />
                : null
            }
            {showSomethingWentWrongScreen ?
                <SomethingWentWrongScreen
                    handleCloseAction={() => handleHomeClick(dispatch, navigate)}
                />
                : null
            }
            {apiCallInProgress ? <Loader showLoading={apiCallInProgress} /> : null}
        </SurveyContainerView>
    )
}

export default SurveyContainer