import { errorToString } from "../helpers/CommonHelper";
import { AnalyticsManager } from "../manager/AnalyticsManager";
import { AuthenticationManager } from "../manager/AuthenticationManager";
import { BaseURL, getAdminAccessToken } from "../utils/ApiRequestUtil";
import { AppConfig } from "../utils/AppConfig";
import { DD_APIEndPointName } from "../utils/DDAPIEndpointName";
import { getAdminTokenResult } from "./AccessTokenMapper";
import { InstallCertificateResponse } from "./apiResultModels/InstallCertificateResponse";

export class APIClientElectron {

    static getRequestWithCertificate(apiName: DD_APIEndPointName, apiURL: string, accessToken: string, params?: any): Promise<string> {
        let apiURLWithQuery = apiURL
        if (params) {
            const queryParams = new URLSearchParams(params).toString();
            apiURLWithQuery = `${apiURL}?${queryParams}`
        }
        if (AuthenticationManager.isCertificateEnv()) {
            return window.electron.getRequestWithCertificate(apiURLWithQuery, accessToken)
                .then(async responseString => {
                    return responseString;
                })
                .catch(error => {
                    AnalyticsManager.logDDError(`${apiName} Failed`, { EndPoint: apiName, FailureReason: errorToString(error) });
                    throw error;
                });
        } else {
            return window.electron.getRequestWithOutCertificate(apiURLWithQuery, accessToken)
                .then(async responseString => {
                    return responseString;
                })
                .catch(error => {
                    AnalyticsManager.logDDError(`${apiName} Failed`, { EndPoint: apiName, FailureReason: errorToString(error) });
                    throw error;
                });
        }
    }

    static getRequestWithOutCertificate(apiName: DD_APIEndPointName, apiURL: string, accessToken: string, params?: any): Promise<string> {
        let apiURLWithQuery = apiURL
        if (params) {
            const queryParams = new URLSearchParams(params).toString();
            apiURLWithQuery = `${apiURL}?${queryParams}`
        }
        return window.electron.getRequestWithOutCertificate(apiURLWithQuery, accessToken)
            .then(async responseString => {
                return responseString;
            })
            .catch(error => {
                AnalyticsManager.logDDError(`${apiName} Failed`, { EndPoint: apiName, FailureReason: errorToString(error) });
                throw error;
            });
    }

    static postRequestWithCertificate(apiName: DD_APIEndPointName, apiURL: string, data: any, accessToken: string): Promise<any> {
        if (AuthenticationManager.isCertificateEnv()) {
            return window.electron.postRequestWithCertificate(apiName, apiURL, accessToken, data)
                .then(async responseString => {
                    return responseString;
                })
                .catch(error => {
                    AnalyticsManager.logDDError(`${apiName} Failed`, { EndPoint: apiName, FailureReason: errorToString(error) });
                    throw error;
                });
        } else {
            return window.electron.postRequestWithOutCertificate(apiName, apiURL, accessToken, data)
                .then(async responseString => {
                    return responseString;
                })
                .catch(error => {
                    AnalyticsManager.logDDError(`${apiName} Failed`, { EndPoint: apiName, FailureReason: errorToString(error) });
                    throw error;
                });
        }
    }

    static postRequestWithOutCertificate(apiName: DD_APIEndPointName, apiURL: string, data: any, accessToken: string): Promise<any> {
        return window.electron.postRequestWithOutCertificate(apiName, apiURL, accessToken, data)
            .then(async responseString => {
                return responseString;
            })
            .catch(error => {
                AnalyticsManager.logDDError(`${apiName} Failed`, { EndPoint: apiName, FailureReason: errorToString(error) });
                throw error;
            });
    }

    static postTokenRequest(apiURL: string, data: any): Promise<string> {
        return window.electron.postTokenRequest(apiURL, data)
            .then(async responseString => {
                return responseString;
            })
            .catch(error => {
                AnalyticsManager.logDDError(`${DD_APIEndPointName.Token} Failed`, { EndPoint: DD_APIEndPointName.Token, FailureReason: errorToString(error) });
                throw error;
            });
    }

    static async postEnrolKioskRequest(): Promise<InstallCertificateResponse> {
        try {
            const apiURL = `${BaseURL.certificate}${AppConfig.CertificateEndPoint}`
            const accessToken = getAdminAccessToken()
            const adminTokenResult = await getAdminTokenResult(accessToken)
            const response = await window.electron.postEnrolKioskRequest(apiURL, `Bearer ${accessToken}`, adminTokenResult.orgERN ?? '', adminTokenResult.cdb ?? '')
            return response
        }
        catch (error) {
            AnalyticsManager.logDDError(`${DD_APIEndPointName.DeviceEnrolment} Failed`, { EndPoint: DD_APIEndPointName.DeviceEnrolment, FailureReason: errorToString(error) });
            throw error;
        }
    }
}