import { useTranslation } from "react-i18next";
import { Strings } from "../../../../../constants/StringConstant"
import { AppointmentListScreenHeader, UserNameContainer, HiText, UserNameText, NotYouButton } from "../../../styles/StylesCheckIn"


interface AppointmentListHeaderViewProps {
    patientName: string,
    handleNotYouAction: () => void,
}

export const AppointmentListHeaderView: React.FC<AppointmentListHeaderViewProps> = (props: AppointmentListHeaderViewProps) => {
    const { t } = useTranslation();
    return (
        <AppointmentListScreenHeader>
            <UserNameContainer>
                <HiText> {t(Strings.Hi)}</HiText>
                <UserNameText> {props.patientName} </UserNameText>
            </UserNameContainer>
            <NotYouButton data-testid="NotYouButton" onClick={props.handleNotYouAction}>
                {t(Strings.NotYou)}
            </NotYouButton>
        </AppointmentListScreenHeader>
    )
}

export default AppointmentListHeaderView